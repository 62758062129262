<div
    class="widget-my-lessons"
    [ngClass]="{'tutor': userdata.isTutor, 'half-view': isHalfView }"
>
    <div class="widget-my-lessons__title">{{ 'Мои занятия' | transloco }}</div>
    <div class="widget-my-lessons__actions">
        <div class="widget-my-lessons__nav">
            <div
                class="widget-my-lessons__nav-item"
                (click)="changeNavItem(navItem)"
                *ngFor="let navItem of navItems; trackBy: trackByNavItem"
                [ngClass]="navItem.isActive ? 'active' : ''"
            >
                {{ navItem.name | transloco }}
            </div>
        </div>
        <div class="widget-my-lessons__actions-buttons">
            <ezteach-button
                class="create-button"
                (click)="openCreateMeetingPopup()"
                *ngIf="userdata.isTutor && navItems[0].isActive"
            >
                <img
                    class="create-button__icon"
                    src="assets/img/svg/icon-add.svg"
                    i18n-alt
                    alt="Создать занятие"
                />
                {{ 'Создать занятие' | transloco }}
            </ezteach-button>

            <ezteach-button
                class="create-button"
                (click)="openCreateMeetingPopup()"
                *ngIf="userdata.isTutor && navItems[1].isActive && !selectedBlockService.selectedBlock$.value"
            >
                <img
                    class="create-button__icon"
                    src="assets/img/svg/icon-add.svg"
                    i18n-alt
                    alt="Создать блок"
                />
                {{ 'Создать блок' | transloco }}
            </ezteach-button>

            <ezteach-button
                class="selection-button"
                *ngIf="navItems[2].isActive"
                (click)="changeSelectMode(!isSelectMode)"
                i18n
            >
                {{ isSelectMode ? ('Отменить выбор' | transloco) : ('Выбрать занятия' | transloco) }}
            </ezteach-button>

            <button
                *ngIf="isSelectMode && navItems[2].isActive"
                [disabled]="selectedLessons.length === 0"
                class="delete-button"
                (click)="deleteLessons()"
            >
                {{ 'Удалить' | transloco }}
            </button>
        </div>
    </div>
    <div class="widget-my-lessons__content">
        <div
            class="widget-my-lessons__list-wrapper"
            [class]="{ 'remove-top': userdata.isTutor && !navItems[0].isActive && !navItems[1].isActive }"
        >
            <ng-container *ngFor="let navItem of navItems">
                <div
                    class="widget-my-lessons__list"
                    *ngIf="navItem.isActive"
                    [class]="{
            'half-view': isHalfView,
            'widget-lessons-empty-wrapper': lessons.length === 0 && navItem.id !== 'blocks'
          }"
                >
                    <ng-container *ngIf="lessons.length > 0 || navItem.id === 'blocks'; else emptyLessons">
                        <div class="lessons">
                            <ng-container *ngIf="navItem.id === 'blocks'">
                                <ng-template *ngTemplateOutlet="blocks"></ng-template>
                            </ng-container>

                            <div
                                class="lesson"
                                [class.hovered]="lesson.isHovered"
                                (mouseover)="lesson.isHovered = true"
                                (mouseleave)="lesson.isHovered = false"
                                *ngFor="let lesson of lessons; trackBy: trackByChatLesson"
                            >
                                <ng-container *ngIf="navItem.id === 'active'; then activeLesson; else otherLesson">
                                </ng-container>

                                <ng-template #activeLesson>
                                    <my-lesson-item
                                        [lesson]="lesson"
                                        [activeTab]="activeTab"
                                        [isActiveLesson]="true"
                                    > </my-lesson-item>
                                </ng-template>

                                <ng-template #otherLesson>
                                    <my-lesson-item
                                        [lesson]="lesson"
                                        [activeTab]="activeTab"
                                    >
                                        <mat-checkbox
                                            first
                                            class="widget-my-lessons__list__container__checkbox"
                                            *ngIf="isSelectMode"
                                            (click)="$event.stopPropagation()"
                                            (change)="toggleSelectionLesson(lesson)"
                                        ></mat-checkbox>
                                    </my-lesson-item>
                                </ng-template>
                            </div>
                        </div>

                        <div
                            class="search__action"
                            *ngIf="totalPages > pageNumber"
                        >
                            <button class="btn btn--blue">
                                <span
                                    class="btn__label"
                                    (click)="getMore(navItem.id)"
                                >{{ 'Показать еще' | transloco }}</span>
                            </button>
                        </div>
                    </ng-container>
                </div>
            </ng-container>
        </div>
    </div>
</div>

<ng-template #blocks>
    <ezteach-block-list (onAddLesson)="addLesson($event)"> </ezteach-block-list>
</ng-template>

<ng-template #emptyLessons>
    <div class="widget-lessons-empty">
        <div>
            <div>
                <img
                    draggable="false"
                    src="assets/img/svg/img-cloud.svg"
                />
            </div>
        </div>
        <div
            class="widget-lessons-empty__header"
            *ngIf="activeTab?.id!='deleted'"
        >{{ 'У вас пока нет ваших занятий' | transloco }}</div>
        <div
            class="widget-lessons-empty__header"
            *ngIf="activeTab?.id=='deleted'"
        >{{ 'Корзина пуста' | transloco }}</div>
        <div
            class="widget-lessons-empty__text"
            *ngIf="!userdata.isTutor && activeTab?.id!='deleted'"
        >
            {{ 'Вы всегда можете уточнить время, дату и домашнее задание в нашем мессенджере' | transloco }}
        </div>
    </div>
</ng-template>