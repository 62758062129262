<a
  class="linkfull"
  routerLink="/teachers/{{ item.id }}"
></a>
<div class="info">
  <div class="image">
    <div class="avatar-image"
          *ngIf="image(item)"
          [style.background]="image(item)"
        ></div>
    <span *ngIf="!image(item)">{{ initials(item) }}</span>
    <div
      class="status"
      [ngClass]="{ online: item.isOnline }"
    ></div>
  </div>
  <div class="rating" *ngIf="item.tutor.rating" >
		<span class="star" [ngClass]="{filled: item.tutor.rating >= 1}"></span>	
	  <span class="star" [ngClass]="{filled: item.tutor.rating >= 2}"></span>	 
	  <span class="star" [ngClass]="{filled: item.tutor.rating >= 3}"></span>	
	  <span class="star" [ngClass]="{filled: item.tutor.rating >= 4}"></span>	
	  <span class="star" [ngClass]="{filled: item.tutor.rating >= 5}"></span>	
	</div>
  <!--div class="lessons">
    {{ item.tutor.chatLessonsAccomplished }}
    <span
      appPlural
      [value]="item.tutor.chatLessonsAccomplished"
      [arVariants]="['занятие', 'занятия', 'занятий']"
    ></span>
  </div-->

  <div class="price">
    
    <div
      class="price__value"
      *ngIf="type === 'bookmark'"
    >
      {{ item.tutor.instantLesson5MinuteRate }} ₽ / {{ item.tutor.scheduledLessonHourlyRate }} ₽
    </div>

    <div
      class="price__value"
      *ngIf="type === 'regular'"
    >
      {{ item.tutor.scheduledLessonHourlyRate }} ₽
    </div>
    <div
      class="price__value"
      *ngIf="type === 'instant'"
    >
      {{ item.tutor.calculatedInstantLessonPrice }} ₽
    </div>
  </div>
</div>
<div
  class="content"
  [ngClass]="{ bookmarked: marked }"
>
  <div
    class="bookmark"
    (click)="bookmark($event)"
    *ngIf="userdata.isStudent"
  >
    <svg-icon-sprite
      [src]="'like-heart'"
      [width]="'29px'"
      [height]="'42px'"
      [viewBox]="'0 0 29 42'"
    ></svg-icon-sprite>
  </div>
  <div class="content__head">
    <div class="name">{{ item.name }}</div>
    
    <div
      class="disciplines"
      *ngIf="getDisciplines(item.tutor) !== ''"
    >
      
      <div class="disciplines__name">
        {{ getDisciplines(item.tutor) }}
      </div>
    </div>
    <div class="portfolio">
      <div
        class="diplom"
        *ngIf="getDiplom(item) !== ''"
      >
        <div class="diplom__icon">
          <svg-icon-sprite
            [src]="'icon-diplom'"
            [width]="'2.5rem'"
            [height]="'2.5rem'"
            [viewBox]="'0 0 25 25'"
          ></svg-icon-sprite>
        </div>
        <div class="diplom__name">
          {{ getDiplom(item).split('&nbsp;').join(' ') }}
        </div>
      </div>
      <div class="reviews">{{reviewsCount}} <span
        appPlural
        [value]="reviewsCount"
        [arVariants]="[' отзыв', ' отзыва', ' отзывов']"
      ></span></div>
      <div class="good-rated" *ngIf="item.tutor.rating>=4">
        {{'Очень хвалят' | transloco}}
      </div>
      <div class="has-time" *ngIf="freetime!=null">
        {{freetime | transloco}}
      </div>
    </div>
  </div>
  <div class="actions">
    <div class="actions__left">
      
    </div>
    <div class="actions__right">
      <a
        routerLink="/teachers/{{ item.id }}/calendar"
        class="btn btn--blue"
        *ngIf="type === 'regular'"
      >
        <span class="btn__label">{{ 'Записаться' | transloco }} </span>
      </a>
      <a
        class="btn btn--blue"
        *ngIf="type === 'instant' && !requestsService.sending$.value"
        (click)="sendRequestInstant(item)"
      >
        <span class="btn__label">{{ 'Запрос на занятие' | transloco }}</span>
      </a>
      <a
        class="btn btn--green"
        *ngIf="type === 'instant' && requestsService.sending$.value"
      >
        <span class="btn__label">{{ 'Запрос отправлен' | transloco }}</span>
      </a>
    </div>
  </div>
</div>
