import { animate, keyframes, state, style, transition, trigger } from '@angular/animations';
import { ConnectionPositionPair } from '@angular/cdk/overlay';
import { DOCUMENT } from '@angular/common';
import { Component, EventEmitter, Inject, Input, OnInit, Output, ViewChild } from '@angular/core';
import { MatCheckboxChange } from '@angular/material/checkbox';
import { LocalStorageService } from '@ezteach/_services/local-storage.service';
import { OrientationService, OrientationTypes } from '@ezteach/_services/orientation.service';
import { UserService } from '@ezteach/_services/user.service';
import { ChatLesson } from '@ezteach/api/models/chat-lesson';
import { ChatLessonMember } from '@ezteach/api/models/chat-lesson-member';
import { ChatLessonMemberPublishingPermission, ChatLessonMemberPublishingStateEnum, ChatLessonMemberRole } from '@ezteach/api/models/chat-lesson-member-permisson';
import { LessonsService } from '@ezteach/api/services';
import { Carousel } from '@ezteach/group-lesson/components/group-lesson-layout/carousel';
import { DragManager } from '@ezteach/group-lesson/components/group-lesson-layout/dragManager';
//import { ResizingTarget } from '@ezteach/group-lesson/components/group-lesson-layout/group-lesson-layout.component';
import { Resizer } from '@ezteach/group-lesson/components/group-lesson-layout/resizer';
import {
  MuteMember,
  ShareScreenStatusMember,
  VideoStatusMember,
} from '@ezteach/group-lesson/components/group-lesson-owner/group-lesson-owner.component';
import { ChatLessonMemberClient } from '@ezteach/group-lesson/models/chat-lesson-member-client';
import { ChatLessonMemberPublishingState } from '@ezteach/group-lesson/models/chat-lesson-member-publishing-state';
import { SortMemberTypes } from '@ezteach/group-lesson/models/sort-member-types.enum';
import { StreamUserData } from '@ezteach/group-lesson/models/stream-user-data';
import { GroupLessonLayoutMergeService, WhiteboardViewState } from '@ezteach/group-lesson/services/group-lesson-layout.service';
import { GroupLessonMemberManagerService } from '@ezteach/group-lesson/services/group-lesson-member-manager.service';
import { GroupLessonPermissionService } from '@ezteach/group-lesson/services/group-lesson-permisson.service/group-lesson-permisson.service';
import { GroupLessonPinService } from '@ezteach/group-lesson/services/group-lesson-pin/group-lesson-pin.service';
import { GroupLessonPublishingStateService } from '@ezteach/group-lesson/services/group-lesson-publishing-state/group-lesson-publishing-state.service';
import { GroupLessonWhiteBoardService } from '@ezteach/group-lesson/services/group-lesson-whiteboard-service/group-lesson-whiteboard-service';
import { GroupLessonService } from '@ezteach/group-lesson/services/group-lesson.service';
import { OpenViduService } from '@ezteach/group-lesson/services/open-vidu.service';
import { FullViewPortGroupLessonService } from '@ezteach/shared/services/full-viewport-group-lesson.service';
import { WhiteboardAppComponent } from '@ezteach/whiteboard/whiteboard-app.component';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { tuiPure } from '@taiga-ui/cdk';
import { ResizedEvent } from 'angular-resize-event';
import * as _ from 'lodash';
import { OrientationType } from 'ngx-device-detector';
import { WhiteBoardMode } from 'projects/ng-konva/src/lib/whiteboard/classes/white-board';
import { WhiteBoardLayoutService } from 'projects/ng-konva/src/lib/whiteboard/services/whiteboard-layout.service';
import { Subject, Subscription, combineLatest, merge } from 'rxjs';
import { debounceTime, tap } from 'rxjs/operators';
import { VideoViewType } from '../group-lesson-header/group-lesson-header.component';

export enum MemberViewMode {
  onlySpeaker,
  horizontal,
  verticalFirst,
  verticalSecond,
}

export enum ResizingTargetMerge {
  ownerElement,
  memberElement,
}

interface ResizingData {
  // target: ResizingTarget;
  event: ResizedEvent;
}

@UntilDestroy()
@Component({
  selector: 'ezteach-group-lesson-member-merge',
  templateUrl: './group-lesson-member.component.html',
  styleUrls: ['./group-lesson-member.component.scss'],
  host: { 'class': 'widget-call-members' },
  animations: [
    trigger('whiteboard', [
      state(
        'disableFullPageMode',
        style({
          position: 'relative',
        }),
      ),
      state(
        'enableFullPageMode',
        style({
          position: 'fixed',
          minWidth: '100%',
          width: '100%',
          zIndex: 999999999,
          height: '100%',
          left: 0,
          top: 0,
        }),
      ),
      transition('disableFullPageMode => enableFullPageMode', [
        animate(
          1300,
          keyframes([
            style({
              position: 'fixed',
              minWidth: '100%',
              width: '100%',
              zIndex: 999999999,
              height: '100%',
              left: 0,
              top: 0,
              opacity: 0,
              offset: 0,
            }),
            style({ opacity: 0, offset: 0.3 }),
            style({ opacity: 1, offset: 1 }),
          ]),
        ),
      ]),
      transition('enableFullPageMode => disableFullPageMode', [
        animate(
          '1s',
          keyframes([
            style({
              position: 'relative',
              height: '100%',
              opacity: 0,
              offset: 0,
              minWidth: '*',
            }),
            style({ opacity: 1, offset: 1 }),
          ]),
        ),
      ]),
    ]),
  ]
})
export class GroupLessonMemberMergeComponent implements OnInit {
  @Input() speakers: string[];
  @Input() memberClients: ChatLessonMemberClient[];
  @Input() ownerClient: ChatLessonMemberClient;
  @Input() chatLesson: ChatLesson;
  @Output() onMuteMemberChanged = new EventEmitter<MuteMember>();
  @Output() onVideoStatusMemberChanged = new EventEmitter<VideoStatusMember>();
  @Output() onShareScreenStatusMemberChanged = new EventEmitter<ShareScreenStatusMember>();
  @Input() isMobile: boolean;
  @Input() isChatOpen: boolean;
  @Input() isOwner: boolean;
  @Input() isModerator: boolean;
  @Input() away: boolean;
  @Input() view: VideoViewType;
  @Input() lazyIniting = false;

  @Input() pinnedMode: boolean;
  @Input() pinnedMember: ChatLessonMemberClient | undefined;
  @Input() memberSortType: SortMemberTypes;
  memberListIsShown = this.groupLessonService.memberListIsShown;

  viewType = VideoViewType;
  pinBlocked = false;
  showMembers = true;

  fullTransitionMode = false;
  isSpeech: boolean;
  memberElemHeight = '';
  memberElemWidth = '';
  ownerElemWidth = '';
  ownerElemHeight = '';
  carouselBtnPosition = '';
  carouselMemberElemsWidth = '';
  membersPermission: ChatLessonMember[] = [];
  openedMember = null;
  splitter: any;
  carousel: Carousel;
  resizer: Resizer;
  blockCarouselBtns = false;
  prevIsActive = true;
  nextIsActive = true;
  prevIsActiveSubs: Subscription;
  nextIsActiveSubs: Subscription;
  hasScrollBar = false;
  hasTransition = false;
  preventChangeOnPin = false;
  hasCarouselBtns = false;
  dragPosition = { x: 0, y: 0 };
  dragManager: DragManager;
  whiteBoardOnPinnedMode = true;

  whiteBoardEnabled = false;
  whiteBoardMode = WhiteBoardMode;
  whiteBoardStarting = false;
  whiteBoardId = '';
  whiteboardFullPageState = 'disableFullPageMode';
  isContainerHidden = false;
  @ViewChild('whiteBoardComponent')
  whiteBoardAppComponent: WhiteboardAppComponent;
  whiteBoardTopOffset: number;
  whiteboardViewState: WhiteboardViewState;
  whiteboardViewStateType = WhiteboardViewState;
  readonly onResizeMemberElement$ = new Subject<ResizingData>();
  readonly onResizeOwnerElement$ = new Subject<ResizingData>();
  // resizingTarget = ResizingTarget;
  scrollMargin: number;
  members: any;
  orientationLandscape: boolean;

  positions = [
    new ConnectionPositionPair({ originX: 'start', originY: 'top' }, { overlayX: 'end', overlayY: 'bottom' }, 40, -6),
  ];

  constructor(
    private groupLessonService: GroupLessonService,
    private fullViewPortGroupLessonService: FullViewPortGroupLessonService,
    private lessonsService: LessonsService,
    private openViduService: OpenViduService,
    private localStorageService: LocalStorageService,
    private groupLessonPermissionService: GroupLessonPermissionService,
    private groupLessonPinService: GroupLessonPinService,
    private groupLessonLayoutService: GroupLessonLayoutMergeService,
    // private renderer: Renderer2,
    @Inject(DOCUMENT) private readonly documentRef: Document,
    private groupLessonPublishingStateService: GroupLessonPublishingStateService,
    private whiteBoardLayoutService: WhiteBoardLayoutService,
    private groupLessonWhiteBoardService: GroupLessonWhiteBoardService,
    private orientationService: OrientationService,
    private userService: UserService,
    private groupLessonMemberManagerService: GroupLessonMemberManagerService,
  ) {
    this.groupLessonService.memberListIsShown$
      .pipe(
        untilDestroyed(this),
        tap(value => {
          this.memberListIsShown = value;
        }),
      )
      .subscribe();



    // пока так - при каждом изменении типа view отображать всех пользователей
    this.groupLessonLayoutService.view$.subscribe(_v => {
      this.showMembers = true;
    });

    this.fullViewPortGroupLessonService.fullTransitionStart$
      .pipe(
        untilDestroyed(this),
        tap(x => {
          this.fullTransitionMode = true;
        }),
      )
      .subscribe();

    this.fullViewPortGroupLessonService.fullTransitionStop$
      .pipe(
        untilDestroyed(this),
        tap(x => {
          this.fullTransitionMode = false;
        }),
      )
      .subscribe();
  }

  ngOnInit() {

    this.groupLessonService.isSpeech
      .pipe(
        untilDestroyed(this),
        tap(v => {
          this.isSpeech = v;
        }),
      )
      .subscribe();


    // подписка автоматически обновляет права для пользователей
    combineLatest([
      this.groupLessonPermissionService.allLessonMembers$,
      this.groupLessonMemberManagerService.memberClients$,
      this.groupLessonMemberManagerService.owner$
    ])
      .pipe(
        untilDestroyed(this),
        tap(([allLessonMembers, memberClients, owner]) => {
          [...memberClients, owner].forEach(memberClient => {
            if (memberClient) {
              const permissions = allLessonMembers.find(member => member.memberId === memberClient.member.memberId)?.publishingPermissions;
              if (permissions) {
                memberClient.member.publishingPermissions = permissions;
                memberClient.member.audioAccess = permissions.includes(ChatLessonMemberPublishingPermission.Audio);
                memberClient.member.videoAccess = permissions.includes(ChatLessonMemberPublishingPermission.Video);
                memberClient.member.shareScreenAccess = permissions.includes(ChatLessonMemberPublishingPermission.Screen);
              }
            }
          });

          this.membersPermission = allLessonMembers;
        })
      )
      .subscribe();


    this.groupLessonPublishingStateService.membersState$
      .pipe(
        untilDestroyed(this),
        tap(x => {
          const wbState = this.getWbState(x);
          this.whiteBoardEnabled = !!wbState;
          this.whiteBoardId = wbState?.arg;
          setTimeout(x => {
            if (this.whiteBoardEnabled) {
              this.whiteBoardAppComponent.initOrCreateWhiteBoard(this.whiteBoardId);
            }
          }, 10);
        }),
      )
      .subscribe();

    this.groupLessonWhiteBoardService.whiteBoardInitStart$
      .pipe(
        untilDestroyed(this),
        tap(x => {
          if (this.view === this.viewType.all) {
            this.groupLessonLayoutService.view$.next(this.viewType.vertical);
          }
          this.groupLessonPinService.pinnedMember$.next(this.ownerClient);
          this.groupLessonPinService.pinnedMode$.next(true);
          this.whiteBoardStarting = x;
          this.whiteBoardOnPinnedMode = x;
          const wbState = this.getWbState(this.groupLessonPublishingStateService.membersState$.value);
          this.whiteBoardEnabled = !!wbState;
          const lessonId = this.groupLessonService.chatLesson$.value.id;
          const localWbId = this.localStorageService.get(`wb${lessonId}`);
          this.whiteBoardId = wbState?.arg ?? localWbId;
          setTimeout(x => {
            this.whiteBoardAppComponent.initOrCreateWhiteBoard(this.whiteBoardId);
            this.groupLessonWhiteBoardService.whiteBoardOpened$.next(true);
            if (localWbId) {
              this.whiteBoardStarting = false;
              this.groupLessonWhiteBoardService.whiteBoardInitEnd$.next(localWbId);
            }

            if (!this.isOwner) {
              this.whiteBoardStarting = false;
            }
          }, 10);
        }),
      )
      .subscribe();

    this.groupLessonLayoutService.layoutView$
      .pipe(
        untilDestroyed(this),
        tap(state => {
          this.whiteboardFullPageState =
            state === WhiteboardViewState.full ? 'enableFullPageMode' : 'disableFullPageMode';
          this.isContainerHidden = state === WhiteboardViewState.full;
        }),
      )
      .subscribe();

    this.onResizeOwnerElement$
      .pipe(
        tap(data => {
          this.whiteBoardLayoutService.width$.next(data.event.newWidth);
          this.whiteBoardLayoutService.height$.next(data.event.newHeight);
        }),
        untilDestroyed(this),
      )
      .subscribe();

    this.orientationService.orientation$
      .pipe(
        untilDestroyed(this),
        tap((orientation: OrientationTypes) => {
          this.orientationLandscape = orientation === OrientationType.Landscape;
        }),
      )
      .subscribe();

    merge(
      this.fullViewPortGroupLessonService.fullTransitionStart$,
      this.fullViewPortGroupLessonService.fullTransitionStop$,
    )
      .pipe(
        debounceTime(500),
        untilDestroyed(this),
        tap(_ => this.recalculateVideoPosition()),
      )
      .subscribe();
  }
  recalculateVideoPosition(): void {
    throw new Error('Method not implemented.');
  }

  emitOnMuteMemberChanged(muteMember: MuteMember) {
    this.onMuteMemberChanged.emit(muteMember);
  }

  emitOnVideoStatusMemberChanged(videoStatusMember: VideoStatusMember) {
    this.onVideoStatusMemberChanged.emit(videoStatusMember);
  }

  emitOnShareScreenStatusMemberChanged(shareScreenStatusMember: ShareScreenStatusMember) {
    this.onShareScreenStatusMemberChanged.emit(shareScreenStatusMember);
  }

  getWbState(x: ChatLessonMemberPublishingState[]) {
    const state = _.flatten(x.map(x => x.currentValue));
    const wbState = state.find(
      s => s.name.toUpperCase() === ChatLessonMemberPublishingStateEnum.Whiteboard.toUpperCase(),
    );
    return wbState;
  }

  onOwnerElemResized(event: ResizedEvent | any) {
    const toolbarHeight = 52;
    const toolbarMarginBottom = 42;
    this.whiteBoardTopOffset = event.newHeight - toolbarHeight - toolbarMarginBottom;

    if ((this.whiteBoardEnabled || this.whiteBoardStarting) && this.dragManager) {
      this.setDragPosition(this.dragManager.calculateCornerPositions());
    }
  }

  setDragPosition(position: { x: number; y: number }) {
    const calculateDragTimeOutId = setTimeout(() => {
      this.dragPosition = position;
      clearTimeout(calculateDragTimeOutId);
    }, 150);
  }

  openMenu(member) {
    if (this.openedMember === member) {
      this.backdropClick(null);
    } else {
      this.backdropClick(null);
      this.openedMember = member;
    }
  }

  isOpen(member) {
    return member === this.openedMember;
  }

  backdropClick($event) {
    this.openedMember = null;
  }

  isMe(member): boolean {
    const user = this.userService.userData$.value;
    return member?.member?.user?.id === user.id;
  }

  @tuiPure
  isAudioChecked(member: ChatLessonMemberClient, membersPermission) {
    if (member) {
      const mem = membersPermission.find(x => x.memberId === member?.member?.memberId);
      if (mem) {
        return mem.publishingPermissions.indexOf(ChatLessonMemberPublishingPermission.Audio) !== -1;
      }
      return true;
    }
    return false;
  }

  @tuiPure
  isVideoChecked(member, membersPermission) {
    if (member) {
      const mem = membersPermission.find(x => x?.user?.id === member?.member?.user?.id);
      if (mem) {
        return mem.publishingPermissions.indexOf(ChatLessonMemberPublishingPermission.Video) !== -1;
      }
      return true;
    }
    return false;
  }

  @tuiPure
  isShareScreenChecked(member, membersPermission) {
    if (member) {
      const mem = membersPermission.find(x => x?.user?.id === member?.member?.user?.id);
      if (mem) {
        return mem.publishingPermissions.indexOf(ChatLessonMemberPublishingPermission.Screen) !== -1;
      }
      return true;
    }
    return false;
  }

  OnPinAudioClicked(member: ChatLessonMemberClient) {
    const data = { userid: member.member.user.id } as StreamUserData;
    this.onMuteMemberChanged.emit({
      userData: data,
      audioEnabled: !this.isAudioChecked(member, this.membersPermission),
    });
  }

  log(val, str) {
    console.log(str, val)
  }

  onPinClicked(member: ChatLessonMemberClient) {
    if (this.memberClients.length === 0) {
      return;
    }
    if (!this.pinnedMode && this.memberClients.length > 0) {
      if (!member) {
        member = this.ownerClient;
      }

      this.groupLessonLayoutService.view$.next(this.viewType.vertical);
    }

    this.groupLessonPinService.pinnedMember$.next(this.pinnedMember !== member ? member : undefined);
    this.groupLessonPinService.pinnedMode$.next(this.pinnedMember !== member ? true : false);

    if ((this.whiteBoardEnabled || this.whiteBoardStarting) && member !== this.ownerClient) {
      this.setDragPosition({ x: 0, y: 0 });
      this.whiteBoardOnPinnedMode = false;
      this.groupLessonLayoutService.updateWhiteboardViewState(WhiteboardViewState.standard);
    }
    if (
      (this.whiteBoardEnabled || this.whiteBoardStarting) &&
      this.pinnedMember !== this.ownerClient &&
      member === this.ownerClient
    ) {
      this.whiteBoardOnPinnedMode = true;
      this.groupLessonWhiteBoardService.whiteBoardInitStart$.next(true);
    }
    if (
      (this.whiteBoardEnabled || this.whiteBoardStarting) &&
      this.pinnedMember === this.ownerClient &&
      member === this.ownerClient
    ) {
      this.setDragPosition({ x: 0, y: 0 });
      this.whiteBoardOnPinnedMode = false;
      this.groupLessonLayoutService.updateWhiteboardViewState(WhiteboardViewState.standard);
    }
  }

  ownerContainerDragReleased(ev: any) {
    const el = ev.source.element.nativeElement;
    const parentEl = this.documentRef.querySelector('.container__members-owner');
    this.dragManager = new DragManager(el, parentEl);
    this.setDragPosition(this.dragManager.calculateCornerPositions());
  }

  audioChanged($event, member: ChatLessonMemberClient) {
    const data = { userid: member.member.user.id } as StreamUserData;
    this.onMuteMemberChanged.emit({
      userData: data,
      audioEnabled: $event.checked,
    });
  }

  videoChanged($event: MatCheckboxChange, member: ChatLessonMemberClient) {
    const data = { userid: member.member.user.id } as StreamUserData;
    this.onVideoStatusMemberChanged.emit({
      userData: data,
      videoEnabled: $event.checked,
    });
  }

  shareScreenChanged($event: MatCheckboxChange, member: ChatLessonMemberClient) {
    const data = { userid: member.member.user.id } as StreamUserData;
    this.onShareScreenStatusMemberChanged.emit({
      userData: data,
      shareScreenEnabled: $event.checked,
    });
  }

  onBanned(member: ChatLessonMemberClient) {
    const data = { userid: member.member.user.id } as StreamUserData;
    const lessonId = this.chatLesson.id;
    const params = {
      lessonId,
      userId: data.userid,
      newRole: ChatLessonMemberRole.None,
    };
    this.lessonsService.chatLessonMemberRoleChanged(params).subscribe();
    this.openViduService.banUser(member.stream);
  }

  onWbCreated($event) {
    this.whiteBoardStarting = false;
    this.groupLessonWhiteBoardService.whiteBoardInitEnd$.next($event);
    const lessonId = this.groupLessonService.chatLesson$.value.id;
    const wbLessson = this.localStorageService.get(`wb${lessonId}`);
    if (!wbLessson) {
      this.localStorageService.set(`wb${lessonId}`, $event);
    }
  }

  updateWhiteboardView(state) {
    this.whiteboardViewState = state ? WhiteboardViewState.full : WhiteboardViewState.standard;
    this.groupLessonLayoutService.updateWhiteboardViewState(this.whiteboardViewState);
  }

}
