import { ChatLessonPrivacy, ChatLessonPublisherPolicy } from '@ezteach/group-lesson/services/group-lesson.service';
import { LabelValue } from '@ezteach/shared/models';

export const MEETING_UI_METADATA = {
  dateTimeLabel: 'Дата и время',
  repeat: 'Повтор',
  groupLessonLabel: 'Занятие',
  blockLabel: 'Блок',
  freeSlotLabel: 'Свободный слот',
  selectBlockLabel: 'Выбрать',
  repeatToolTop:'При создании повторяющихся событий создается10 событий с идентичными параметрами',
  copyLinkTooltip: 'В открытом занятии, доступ по ссылке доступен всем, в закрытом—списку приглашенных',
  copyLinkInviteTooltip: 'Чтобы добавить ученика в список приглашенных, отправьте ему ссылку на инвайт',
  copyLinkMessageWithoutSpec:
    'Вы не можете начать занятие без выбора специализации. Пожалуйста, перейдите в личный кабинет и заполните данный блок.',
  copyLindMessageWithoutValidation:
    'Вы не можете начать занятие пока ваш профиль не будет одобрен администратором. Пожалуйста, подождите.',
  tooltipContent:
    'Здесь вы можете выбрать режим занятия. В режиме семинара ученики смогут включать камеру и микрофон, а в режиме лекции нет.',
  lessonPrivacyTooltipContent: 'Открытое занятие видно всем, а закрытое видно только его участникам.',
  accessToArchiveLessonTooltipContent:
    'Здесь вы можете выбрать необходимо ли записывать занятие по умолчанию для последующего просмотра занятия в записи. Занятие в записи будет доступно после завершения занятия и обработки системой.',
  commonApiErrorText: 'Во время сохранения произошла ошибка, возможно вы некорректно заполнили одно из полей',
  chatLessonPublisherPolicies: [
    {
      value: ChatLessonPublisherPolicy.Any,
      label: 'Семинар',
    },
    {
      value: ChatLessonPublisherPolicy.Owner,
      label: 'Лекция',
    },
  ] as LabelValue<ChatLessonPublisherPolicy>[],
  chatLessonPrivacies: [
    {
      value: ChatLessonPrivacy.Public,
      label: 'Открытое занятие',
    },
    {
      value: ChatLessonPrivacy.Private,
      label: 'Закрытое занятие',
    },
  ] as LabelValue<ChatLessonPrivacy>[],
} as const;
