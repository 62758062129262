import { Pipe, PipeTransform } from '@angular/core';
import {DomSanitizer, SafeHtml } from '@angular/platform-browser';

@Pipe({
  name: 'linkify',
})
export class LinkifyPipe implements PipeTransform {
  constructor(private _domSanitizer: DomSanitizer) {}

  transform(value: string): SafeHtml | string {
    if (!value) {
      return '';
    }

    var result: string = this.stylize(value);
    if (result.includes('</a>')) {
      var htmlObject = document.createElement('div');
      htmlObject.innerHTML = result;
      var all = htmlObject.getElementsByTagName("*");//Получаем все элементы
      for (var i = 0; i < all.length; i++) {
        var attr=all[i].attributes;
        for (var j=0; j<all[i].attributes.length; j++){
            if (attr[j].name!="href" || attr[j].value.indexOf("javascript")!=-1){//Удаляем все аттрибуты кроме href и вызовы javascript из href
              all[i].removeAttribute(attr[j].name);
            }
        }
          
      }
      return this._domSanitizer.bypassSecurityTrustHtml(htmlObject.innerHTML)
    }
    if (result.includes('<')) {
      return result
        .split('')
        .map(char => (char === '<' ? '&lt' : char))
        .join('');
    }

    return result;
  }

  private stylize(text: string): string {
    const arrayFromString: string[] = text.split(' ');
    return arrayFromString.reduce((acc, cur) => {
      if ((cur.startsWith('http://') || cur.startsWith('https://')) && cur.length > 1) {
        return `${acc} <a href="${cur}" target="_blank">${cur}</a> `;
      } else {
        return `${acc} ${cur}`;
      }
    }, '');
  }
}
