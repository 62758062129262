<a
  class="toggle-menu"
  (click)="handleToggleButtonClick()"
>
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <rect
      y="20"
      width="24"
      height="4"
      rx="2"
      fill="white"
    />
    <rect
      y="10"
      width="24"
      height="4"
      rx="2"
      fill="white"
    />
    <rect
      width="24"
      height="4"
      rx="2"
      fill="white"
    />
  </svg>
</a>
<div class="header__inner-wrapper">
  <a
    routerLink="/"
    class="logotype"
    [class.isDisabled]="activeTemporarySolution"
  >
    <svg-icon-sprite
      [src]="setLogo()"
      [width]="'107px'"
      [height]="'51px'"
      [viewBox]="'0 0 107 51'"
    ></svg-icon-sprite>
  </a>

  <div class="header__right_group">
    <online
      *ngIf="isTutor && showOnline && !isMobile"
      class="online-container"
    >
    </online>
    <div
      class="header-divider"
      *ngIf="!isMobile"
    >
    </div>
    <div class="nav">
      <a
        *ngIf="userdata.isStudent"
        routerLink="/search/regular"
        class="btn btn--blue"
        [class.isDisabled]="activeTemporarySolution"
      ><span class="btn__icon">
          <svg-icon-sprite
            [src]="'icon-search'"
            [width]="'20px'"
            [height]="'20px'"
            [viewBox]="'0 0 20 20'"
          ></svg-icon-sprite>
        </span><span class="btn__label"> {{ 'Найти преподавателя' | transloco }}</span></a>

      <div
        *ngIf="!isMobile"
        ezteachTutorialStep
        [tutorialElementName]="$any('notifications-link')"
        (click)="openNotificationDialog()"
        [class.isDisabled]="activeTemporarySolution"
      >
        <div class="belt-icon-wrapper">
          <img
            [src]="isUnwatchedNotifications ? 'assets/img/svg/icon-belt-unwatch.svg' : 'assets/img/svg/icon-belt.svg'"
          />
        </div>
      </div>

      <div
        class="header-avatar"
        (click)="toggleMenu()"
        cdkOverlayOrigin
        #trigger="cdkOverlayOrigin"
      >
        <div class="avatar-image"
          *ngIf="userdata?.avatarFileName; else avatar"
          [style.background]="userdata | userAvatarBackground"
        ></div>
        
        <ng-template #avatar>
          <div>{{ userdata | userInitials }}</div>
        </ng-template>
      </div>
    </div>
  </div>
</div>

<ng-template
  cdkConnectedOverlay
  [cdkConnectedOverlayOrigin]="trigger"
  [cdkConnectedOverlayOpen]="isUserMenuOpen"
  [cdkConnectedOverlayHasBackdrop]="true"
  [cdkConnectedOverlayBackdropClass]="'cdk-overlay-transparent-backdrop'"
  (backdropClick)="toggleMenu()"
  [cdkConnectedOverlayPositions]="isMobile ? mobileProfileMenuPositions : profileMenuPositions"
>
  <ezteach-profile-menu
    [userdata]="userdata"
    (onOpenSubscribe)="toggleMenu()"
    (onOpenFeedback)="openFeedbackDialog()"
    (onOpenProfile)="toggleMenu()"
    (onLogout)="isParticipateActiveLesson()"
  >
  </ezteach-profile-menu>
</ng-template>