<div
  cdkDrag
  cdkDragBoundary=".page-container"
  class="container"
  [ngClass]="{ mobile: isMobile }"
  *ngIf="membersWithOwner.length > 0 && !isWindowClosed"
>
  <ng-container *ngFor="let memberClient of membersWithOwner">
    <ezteach-ov-video-merge
      [memberClient]="memberClient"
      [speakers]="speakers"
      [away]="ownerClient?.member?.memberId === memberClient?.member?.memberId ? isAway : false"
      [style.display]="member === memberClient ? '' : 'none'"
    >
      <div
        [style.display]="(member === ownerClient ? !isAway : true) ? 'none' : ''"
        class="away-block"
      >
        <img
          src="assets/img/content/tutor-away-layout.svg"
          class="away-img"
        />
        <p class="msg-header">{{ 'Преподаватель отошел' | transloco }} </p>
        <p class="msg-description">
          {{ 'Возможно, он случайно закрыл вкладку с занятием' | transloco }} ,<br />
          {{ 'не переживайте, он скоро вернется' | transloco }}
        </p>
      </div>
    </ezteach-ov-video-merge>
  </ng-container>

  <button
    class="container__link"
    (singleClick)="openGroupLesson()"
    [debounceValue]="100"
    ezteachAntiQuickClick
  >
    <img
      class="container__link-btn-img"
      src="assets/img/svg/group-lesson/embedded-window-link.svg"
    />
  </button>
</div>