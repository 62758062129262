import {
  ChangeDetectionStrategy,
  Component,
  HostBinding,
  Inject,
  OnInit
} from '@angular/core';
import { Router } from '@angular/router';
import { ButtonType } from "@ezteach/_components/buttons/button/button.component";
import { ChatLesson } from '@ezteach/api/models';
import { ChatLessonMember } from '@ezteach/api/models/chat-lesson-member';
import { CALENDAR_POPUP_DATA, CALENDAR_POPUP_REF } from '@ezteach/calendar/constants';
import { ICaledarPopupRef } from '@ezteach/calendar/models';
import { ChatLessonPrivacy } from '@ezteach/group-lesson/services/group-lesson.service';
import { UntilDestroy } from '@ngneat/until-destroy';
import * as moment from 'moment';
import { MEETING_UI_METADATA } from '../create-meeting-popup';

@UntilDestroy()
@Component({
  templateUrl: './open-meeting-popup.component.html',
  styleUrls: ['./open-meeting-popup.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class OpenMeetingPopupComponent implements OnInit {
  @HostBinding('class.ezteach-calendar-popup') className = true;
  @HostBinding('class.mobile') mobileClass = false;

  readonly dataUI = MEETING_UI_METADATA;
  readonly buttonType = ButtonType;

  public lessonDate: string = '';
  public lessonDateTime: string = '';
  public membersDisplayText: string = '';
  public themeLesson: string = '';
  public isPublicLesson: boolean = false;
  public disciplineName: string = '';
  public tutorName: string = '';
  public countMembers: number = 0;

  constructor(
    private router: Router,
    @Inject(CALENDAR_POPUP_REF)
    private readonly popupRef: ICaledarPopupRef,
    @Inject(CALENDAR_POPUP_DATA)
    public readonly data: ChatLesson,
  ) { }

  cancel(): void {
    this.popupRef.close();
  }

  initiateLesson() {
    this.router.navigate(['/', 'wait'], { queryParams: { 'id': this.data.joinIdentity } })
  }

  ngOnInit(): void {
    
    if (this.data) {
      this.themeLesson = this.data.subject;

      if (this.data.members?.length) {
        this.tutorName = this.data.members.find((v: ChatLessonMember) => v.isTutor)?.name || "";

        const membersWithoutTutor = this.data.members.filter((v: ChatLessonMember) => !v.isTutor);

        this.membersDisplayText = membersWithoutTutor.map((v: ChatLessonMember) => v.name).join(', ');
        this.countMembers = membersWithoutTutor.length;
      }

      if (this.data.publishingPolicy?.lessonPrivacy === ChatLessonPrivacy.Public) {
        this.isPublicLesson = true;
      }
      else{
        this.isPublicLesson = false;
      }

      this.lessonDate = moment(this.data.lessonStartDate).locale('ru').format('DD MMMM');
      this.lessonDateTime = moment(this.data.lessonStartDate).locale('ru').format('HH:mm');

      this.disciplineName = this.data.discipline?.name ?? $localize`Занятие №${this.data.id}`;
    }


  }

  ngOnDestroy() { }
}
