import { Discipline, User } from '@ezteach/api/models';
import { IDateRange } from '@ezteach/calendar/models';
import { SelectedPublishingPolicy } from '@ezteach/group-lesson/services/group-lesson.service';
import { Observable } from 'rxjs';

export interface GroupLessonOptions {
  lessonId?: number;
  joinIdentity?: string;
  lessonWillBeRecorded?: boolean;
  inviteIdentity?: string;
  selectedDisciplineId: number;
  intervalId?: number;
  disciplines: Array<Discipline>;
  selectedPublishingPolicy: SelectedPublishingPolicy;
  meetingIsCreated?: boolean;
  subject?: string;
  homeWorkTemplateId?: number;
  homeWorkTemplateName?: string;
  invitedUsers?: User[];
}

export interface IMeetingPopupData {
  meetingTimeChange$: Observable<IDateRange>;
  updatePosition$: Observable<void>;
  groupLessonOptions: GroupLessonOptions;
  canEditTime: boolean;
  currentMeetings?: any[];
  popupMode: PopupMode;
  subject?: string;
  isCreateFromWidget?: boolean;
  popupState?: PopupState;
}

export enum PopupMode {
  Create = 'create',
  Edit = 'edit',
}

export enum PopupState {
  'groupLesson',
  'block',
  'selectLesson',
}

export interface ICreateMeetingPopupClose {
  status: boolean;
  lessonDate?: string;
  start?: Date | string;
  end?: Date | string;
  disciplineId?: number;
  publishingPolicy?: SelectedPublishingPolicy;
  lessonId?: number;
  invitedUsers?: number[];
  subject?: string;
  homeWork_template_id?: number;
  historyLessons?: any[];
  lessonWillBeRecorded?: boolean;
  intervalId?: number;
}


export interface ICreateSlotPopupClose {
  eventId?: number;
  start?: Date | string;
  end?: Date | string;
  homeWork_template_id?: number;
}
