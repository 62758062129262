import { BreakpointObserver } from '@angular/cdk/layout';
import { DOCUMENT, Location } from '@angular/common';
import { APP_ID, Component, ElementRef, HostListener, Inject, OnInit, ViewChild } from '@angular/core';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { ActivatedRoute, Router } from '@angular/router';
import { ProlongService } from '@ezteach/_components/prolong/prolong.service';
import { ActiveService } from '@ezteach/_services/active.service';
import { MediaQueryService } from '@ezteach/_services/media-query.service';
import { OrientationService, OrientationTypes } from '@ezteach/_services/orientation.service';
import { SignalrChatService } from '@ezteach/_services/signalr.chat.service';
import { SignalrService } from '@ezteach/_services/signalr.service';
import { AttachmentTypeEnum, ChatLesson, ChatLessonStatusEnum } from '@ezteach/api/models';
import { ChangeFieldsApiResponse } from '@ezteach/api/models/chat-lesson-fieldValue';
import { ChatLessonMember, RequestChangePublishingStateWithId } from '@ezteach/api/models/chat-lesson-member';
import {
  ChatLessonMemberPublishingPermission,
  ChatLessonMemberPublishingStateEnum,
  ChatLessonMemberRole,
} from '@ezteach/api/models/chat-lesson-member-permisson';
import { ChatMessage } from '@ezteach/api/models/chat-message';
import { ReactionTypeEnum } from '@ezteach/api/models/lesson/reaction-enum';
import { LessonProlongData } from '@ezteach/api/models/prolong-data';
import { LessonsService } from '@ezteach/api/services';
import { StrictHttpResponse } from '@ezteach/api/strict-http-response';
import { ChatLessonMemberClient } from '@ezteach/group-lesson/models/chat-lesson-member-client';
import { SortMemberTypes } from '@ezteach/group-lesson/models/sort-member-types.enum';
import { GroupLessonLayoutMergeService } from '@ezteach/group-lesson/services/group-lesson-layout.service';
import { GroupLessonMemberManagerService } from '@ezteach/group-lesson/services/group-lesson-member-manager.service';
import {
  DefaultIconType,
  GroupLessonNotificationsService,
  NotificationSettings,
  SystemUser,
} from '@ezteach/group-lesson/services/group-lesson-notifications/group-lesson-notifications.service';
import { GroupLessonParticipantsOverlayService } from '@ezteach/group-lesson/services/group-lesson-participants-overlay/group-lesson-participants-overlay.service';
import { GroupLessonPermissionService } from '@ezteach/group-lesson/services/group-lesson-permisson.service/group-lesson-permisson.service';
import { GroupLessonPinService } from '@ezteach/group-lesson/services/group-lesson-pin/group-lesson-pin.service';
import { GroupLessonPublishingStateService } from '@ezteach/group-lesson/services/group-lesson-publishing-state/group-lesson-publishing-state.service';
import { GroupLessonReactionService } from '@ezteach/group-lesson/services/group-lesson-reaction-service/group-lesson-reaction.service';
import { GroupLessonSignalrService } from '@ezteach/group-lesson/services/group-lesson-signalr-service/group-lesson-signalr-service';
import { GroupLessonWaitService } from '@ezteach/group-lesson/services/group-lesson-wait/group-lesson-wait.service';
import { ChatLessonPrivacy, ChatLessonPublisherPolicy, GroupLessonService } from '@ezteach/group-lesson/services/group-lesson.service';
import { OpenViduService } from '@ezteach/group-lesson/services/open-vidu.service';
import { ModalDisableMediaByModerator } from '@ezteach/modals/disable-media-by-moderator/modal-disable-media-by-moderator';
import { ModalGroupLessonFinish } from '@ezteach/modals/group-lesson-finish/modal-group-lesson-finish.component';
import { ModalLessonBanned } from '@ezteach/modals/lesson-banned/modal-lesson-banned.component';
import { ModalLessonDone } from '@ezteach/modals/lesson-done/modal-lesson-done.component';
import { ModalLessonFinished } from '@ezteach/modals/lesson-finished/modal-lesson-finished.component';
import { ModalUnavailableCamera } from '@ezteach/modals/unavailable-camera/unavailable-camera.component';
import { FullViewPortGroupLessonService } from '@ezteach/shared/services/full-viewport-group-lesson.service';
import { ET_IS_APPLE_MOBILE } from '@ezteach/shared/tokens/is-mobile-apple';
import { WINDOW } from '@ng-web-apis/common';
import { TranslocoService } from '@ngneat/transloco';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { ResizedEvent } from 'angular-resize-event';
import { sortBy } from 'lodash';
import * as moment from 'moment';
import { OrientationType } from 'ngx-device-detector';
import { Publisher, StreamManager } from 'openvidu-browser';
import { of } from 'ramda';
import { EMPTY, Observable, Subject, Subscription, interval } from 'rxjs';
import { catchError, debounceTime, filter, first, map, switchMap, take, takeUntil, tap } from 'rxjs/operators';
import { LocalStorageService } from '../_services/local-storage.service';
import { GroupLessonDialogFirstLoadComponent } from './components/group-lesson-dialog-first-load/group-lesson-dialog-first-load.component';
import { VideoViewType } from './components/group-lesson-header/group-lesson-header.component';
import { AccessTypes } from './components/group-lesson-moderate-dropdown/group-lesson-moderate-dropdown.component';
import {
  MuteMember,
  ShareScreenStatusMember,
  VideoStatusMember,
} from './components/group-lesson-owner/group-lesson-owner.component';

export type groupMode = 'one' | 'many';
export type UserRole = 'owner' | 'member';

export enum UserRoles {
  Owner = 'owner',
  Member = 'member',
}

export function convertChatLessonMemberRoleToUserRoles(chatLessonMemberRole: ChatLessonMemberRole): UserRoles {
  switch (chatLessonMemberRole) {
    case ChatLessonMemberRole.Owner:
      return UserRoles.Owner;
    default:
      return UserRoles.Member;
  }
}


interface ChatLessonMemberClientWrapper {
  actual: ChatLessonMemberClient;
  expected: ChatLessonMemberClient;
}

function sortWrapperMembersFn(
  items: ChatLessonMemberClientWrapper[],
  sortType: SortMemberTypes,
  speakerConnections: string[],
): ChatLessonMemberClientWrapper[] {
  if (sortType === SortMemberTypes.AlphabeticallyAsc) {
    return sortWrapperByExpectedName(items);
  } else if (SortMemberTypes.SpeakingAsc === sortType) {
    const speakerIds = items
      .filter(m => speakerConnections.includes(m?.expected?.stream?.stream?.connection?.connectionId))
      .map(m => m?.expected?.member?.user?.id);
    const speakers = items.filter(m => speakerIds.includes(m?.expected?.member?.user?.id));
    const others = items.filter(m => !speakerIds.includes(m?.expected?.member?.user?.id));
    return [...sortWrapperByExpectedName(speakers), ...sortWrapperByExpectedName(others)];
  }
  return items;
}

function sortWrapperByExpectedName(items: ChatLessonMemberClientWrapper[]) {
  return sortBy(items, m => m.expected.member?.user?.name);
}

export interface RightStatusChange {
  type: ChatLessonMemberPublishingPermission;
  enabled: boolean;
}

@UntilDestroy()
@Component({
  selector: 'ezteach-group-lesson',
  templateUrl: './group-lesson.component.html',
  styleUrls: ['./group-lesson.component.scss'],
})
export class GroupLessonMergeComponent implements OnInit {
  mode: groupMode = 'one';
  currentPublisher: Publisher;
  members: StreamManager[];
  private readonly sortMembers$ = new Subject<void>();
  memberClients: ChatLessonMemberClient[] = [];
  memberSortType = SortMemberTypes.AlphabeticallyAsc;
  ownerClient: ChatLessonMemberClient;
  owner: Publisher | StreamManager;
  isMobile = false;
  isOwner: boolean = false;
  isModerator: boolean = false;
  started: boolean = false;
  myMember: ChatLessonMember;
  userRole: UserRole;
  speakers: string[];
  lessonLink: string;
  inviteLink: string;
  dialogRef: MatDialogRef<any>;
  isAway: boolean;
  userTotalCount: number;
  canProlong = false;
  prolongData: LessonProlongData;
  prolongVisible = false;

  chatLesson: ChatLesson;
  chatLessonStatusEnum = ChatLessonStatusEnum;
  queryChatLessonId?: string = null;

  subscription: Subscription = new Subscription();

  audioEnabled: boolean;
  videoEnabled: boolean;
  shareActive: boolean;
  shareDisabled: boolean;
  isSpeech = false;
  isStarted = false;
  previousAudioState;

  audioPermission = false;
  videoPermission = false;
  sharePermission = false;
  unreadMessageCounter = 0;
  private mediaQueryService = new MediaQueryService('(max-width: 1279.9px)');
  // private mediaQueryServiceUserViewWithOpenedChat = new MediaQueryService('(max-width: 1430px)');
  mutationObserver: MutationObserver;
  viewType = VideoViewType;
  view: VideoViewType | undefined = this.viewType.all;
  pinnedMode = false;
  pinnedMember: ChatLessonMemberClient | undefined = undefined;
  orientationLandscape: boolean;
  publisherStateChangingValue = false;
  lazyIniting = false;

  reconnecting = false;
  networkDisconnect = false;
  topPositionToolbarOffsetByChat: number;
  shrinkByChat = false;
  virtualKeyboardDisplayed = false;
  fromEmbeddedWindow = false;
  narrowScreen = false;
  narrowScreenBreakPointHeight = '900px';
  errorMessage: string | undefined = undefined;

  @ViewChild('groupLessonContainer', { static: false }) groupLessonContainer: ElementRef;

  @ViewChild('audio') audio: ElementRef<HTMLElement>;

  constructor(
    private openViduService: OpenViduService,
    public groupLessonService: GroupLessonService,
    private lessonsService: LessonsService,
    private activatedRoute: ActivatedRoute,
    private router: Router,
    private localStorageService: LocalStorageService,
    private signalrChatService: SignalrChatService,
    private signalrService: SignalrService,
    private groupLessonSignalrService: GroupLessonSignalrService,
    public groupLessonPermissionService: GroupLessonPermissionService,
    public groupLessonPublishingStateService: GroupLessonPublishingStateService,
    private dialog: MatDialog,
    private activeService: ActiveService,
    private groupLessonWaitService: GroupLessonWaitService,
    private groupLessonMemberManagerService: GroupLessonMemberManagerService,
    private groupLessonLayoutService: GroupLessonLayoutMergeService,
    private groupLessonPinService: GroupLessonPinService,
    private groupLessonReactionService: GroupLessonReactionService,
    @Inject(WINDOW) private windowRef: Window,
    @Inject(DOCUMENT) private readonly documentRef: Document,
    private groupLessonNotificationsService: GroupLessonNotificationsService,
    private groupLessonParticipantsOverlayService: GroupLessonParticipantsOverlayService,
    private prolongService: ProlongService,
    private location: Location,
    public fullViewPortGroupLessonService: FullViewPortGroupLessonService,
    private breakpointObserver: BreakpointObserver,
    private orientationService: OrientationService,
    private readonly matDialog: MatDialog,
    private translocoService: TranslocoService,
    @Inject(ET_IS_APPLE_MOBILE) public readonly isIOS: boolean,
    @Inject(APP_ID) private appId: string
  ) { }

  ngAfterViewInit() {
    if (this.groupLessonContainer && !this.isMobile) {
      setTimeout(() => {
        this.windowRef?.scrollTo(0, 1 * parseFloat(getComputedStyle(document.documentElement).fontSize));
        this.addAudioOutputObserver(
          this.groupLessonContainer,
          this.localStorageService.get('audioOutputDeviceId'),
          this.openViduService,
        );
      }, 100);
    }
  }

  ngOnInit() {
    this.orientationService.orientation$
      .pipe(
        untilDestroyed(this),
        tap((orientation: OrientationTypes) => {
          this.orientationLandscape = orientation === OrientationType.Landscape;
        }),
      )
      .subscribe();

    this.queryChatLessonId = this.activatedRoute.snapshot.queryParams.id;

    this.localStorageService.set('groupLessonId', Number(this.queryChatLessonId));

    this.activatedRoute.queryParams.pipe(untilDestroyed(this)).subscribe(params => {
      if (params.fromEmbeddedWindow) {
        this.fromEmbeddedWindow = true;
        this.location.replaceState(`${location.pathname}?id=${this.queryChatLessonId}`);
        this.subscription.unsubscribe();
      } else {
        this.fromEmbeddedWindow = false;
      }
    });

    this.groupLessonService.timeDuration$
      .pipe(
        untilDestroyed(this),
        tap(lessonTimeEnd => {
          const minuteLeft = moment(lessonTimeEnd).minute();
          const secondLeft = moment(lessonTimeEnd).second();
          const totalSecondLeft = minuteLeft * 60 + secondLeft;
          this.prolongService.leftSeconds.next(totalSecondLeft);
        }),
      )
      .subscribe();

    // this.mediaQueryServiceUserViewWithOpenedChat.match$.subscribe(value => {
    //   if (value && this.isChatOpen && this.view === this.viewType.verticalSecond) {
    //     this.view = this.viewType.verticalFirst;
    //   }
    // });

    this.mediaQueryService.match$
      .pipe(
        untilDestroyed(this),
        tap(x => {
          this.isMobile = x;
        }),
      )
      .subscribe();

    this.groupLessonService.setQueryChatLessonId(this.queryChatLessonId);

    this.subscribePermission();
    this.subscribeWebRtcNetworkService();

    this.openViduService.publisher$
      .pipe(
        untilDestroyed(this),
        filter(x => !!x),
        filter(() => this.userRole !== ChatLessonMemberRole.Owner.toLowerCase()),
        tap(x => {
          this.currentPublisher = x;
          this.groupLessonMemberManagerService.setStream(x);
          this.groupLessonService.updateLessonMembers();
        }),
      )
      .subscribe();

    this.openViduService.members$.pipe(untilDestroyed(this)).subscribe(x => {
      this.members = x;
      this.groupLessonMemberManagerService.setStreams(x);
      this.groupLessonService.updateLessonMembers();
    });

    this.openViduService.owner$
      .pipe(
        untilDestroyed(this),
        filter(x => !!x),
      )
      .subscribe(x => {
        this.owner = x;
        this.groupLessonMemberManagerService.setStream(x);
        this.groupLessonService.updateLessonMembers();
      });

    this.openViduService.screenPublish$
      .pipe(
        untilDestroyed(this),
        tap((publish: boolean) => {
          this.groupLessonNotificationsService.soundNotification$.next();
          if (publish) {
            this.groupLessonNotificationsService.createNotification(this.myMember, 'Сейчас Вы демонстрируете экран.');
          } else {
            this.groupLessonNotificationsService.createNotification(
              this.myMember,
              'Вы прекратили демонстрацию экрана.',
            );
          }
        }),
      )
      .subscribe();

    this.openViduService.screenPublisher$
      .pipe(
        untilDestroyed(this),
        filter(x => !!x),
        filter(() => this.userRole !== ChatLessonMemberRole.Owner.toLowerCase()),
        tap(x => {
          this.groupLessonMemberManagerService.setStream(x);
          this.groupLessonService.updateLessonMembers();
        }),
      )
      .subscribe();

    this.openViduService.publish$
      .pipe(
        untilDestroyed(this),
        tap((enabled: boolean) => {
          this.groupLessonNotificationsService.soundNotification$.next();
          if (enabled) {
            this.groupLessonNotificationsService.createNotification(
              this.myMember,
              `Вы начали трансляцию видео с камеры ${this.localStorageService.get('videoDeviceLabel')}`,
            );
          } else {
            this.groupLessonNotificationsService.createNotification(
              this.myMember,
              `Вы прекратили трансляцию с камеры ${this.localStorageService.get('videoDeviceLabel')}`,
            );
          }
        }),
      )
      .subscribe();

    this.openViduService.audioEnabled$.pipe(untilDestroyed(this)).subscribe(x => {
      this.audioEnabled = x;
    });

    this.openViduService.videoEnabled$.pipe(untilDestroyed(this)).subscribe(x => {
      this.videoEnabled = x;
    });

    this.openViduService.shareActive$.pipe(untilDestroyed(this)).subscribe(x => {
      this.shareActive = x;
    });

    this.openViduService.shareDisabled$
      .pipe(
        untilDestroyed(this),
        tap(x => {
          this.shareDisabled = x;
        }),
      )
      .subscribe();

    this.openViduService.speakers$.pipe(untilDestroyed(this)).subscribe(speechIds => {
      this.speakerDetection(speechIds);
    });

    this.openViduService.publisherStateChangingValue$.pipe(untilDestroyed(this)).subscribe(x => {
      this.publisherStateChangingValue = x;
    });

    this.openViduService.lazyIniting$.pipe(untilDestroyed(this)).subscribe(x => {
      this.lazyIniting = x;
    });

    this.openViduService.lazyInitingAudioSuccess$.pipe(untilDestroyed(this)).subscribe(x => {
      this.groupLessonService.changePublishingState(
        ChatLessonMemberPublishingStateEnum.Audio,
        this.openViduService.audioEnabled,
        '',
      );
    });
    this.openViduService.lazyInitingVideoSuccess$.pipe(untilDestroyed(this)).subscribe(x => {
      this.groupLessonService.changePublishingState(
        ChatLessonMemberPublishingStateEnum.Video,
        this.openViduService.videoEnabled,
        '',
      );
    });

    this.groupLessonService.chatLesson$
      .pipe(
        untilDestroyed(this),
        filter(x => !!x),
        tap(x => {
          this.chatLesson = x;
          this.isSpeech = x.publishingPolicy.publisher === ChatLessonPublisherPolicy.Owner;
          this.groupLessonService.isSpeech.next(this.isSpeech);
          this.isStarted = true;
        }),
      )
      .subscribe();

    this.getLesson()
      .pipe(
        untilDestroyed(this),
        tap(chatLesson => {
          this.initializeLesson(chatLesson);
        }),
      )
      .subscribe();

    this.subscribeSignalr();
    this.subscribeGroupLessonSignalr();
    this.groupLessonService.subscribeSignals();
    this.initServiceSubscriptions();

    this.signalrChatService.onUserMessageSent
      .pipe(
        untilDestroyed(this),
        tap(({ chatMessage }) => {
          const sender = chatMessage.sentByMember;
          if (sender.memberId !== this.myMember.memberId) {
            this.notificateUserAboutMessage(chatMessage);
            this.unreadMessageCounter++;
          }
        }),
      )
      .subscribe();

    this.groupLessonMemberManagerService.memberClients$.pipe(untilDestroyed(this)).subscribe(x => {
      this.groupLessonLayoutService.containerStylesToDefault$.next(x.length);

      if (x.length > 0 && this.view === undefined) {
        this.view = this.viewType.vertical;
        this.pinnedMode = true;
      }

      if (
        this.pinnedMember !== undefined &&
        this.pinnedMember !== this.ownerClient &&
        x.findIndex(x => x === this.pinnedMember) === -1
      ) {
        this.view = this.viewType.all;
        this.pinnedMember = undefined;
        this.pinnedMode = false;
      }
      this.memberClients = [...x];
      this.sortMembers$.next();
      // добавлѝем "1", потому что owner-a нет в ѝпиѝке memberClients
      this.userTotalCount = this.memberClients.length + 1;
      this.openViduService.setMemberSubscriberPublisherEnabled(this.userTotalCount > 10);
    });

    this.groupLessonMemberManagerService.owner$.pipe(untilDestroyed(this)).subscribe(x => {
      this.ownerClient = x;
      if (this.ownerClient?.member) {
        if (this.pinnedMember?.member?.role === ChatLessonMemberRole.Owner) {
          this.pinnedMember = this.ownerClient;
        }
        this.groupLessonService.isAway$.next(false);
      }
    });

    this.groupLessonService.isAway$
      .pipe(
        tap(away => {
          this.isAway = away;
        }),
      )
      .subscribe();

    this.groupLessonService.canProlong$
      .pipe(
        tap(data => {
          this.groupLessonService.isProlongModalVisible$.next(data);
        }),
        filter(data => !!data),
        tap(() => {
          this.groupLessonNotificationsService.createNotification(
            this.myMember,
            'Занятие скоро закончится. Вы можете его продлить в течение 5 минут',
            null,
            'Продление занятия',
          );

          this.groupLessonNotificationsService.soundNotification$.next();
        }),
      )
      .subscribe();

    this.groupLessonService.isProlongModalVisible$
      .pipe(
        tap(visible => {
          if (visible) {
            this.prolongData = this.groupLessonService.canProlongData$.value;
          }
          this.errorMessage = undefined;
          this.prolongVisible = visible;
        }),
      )
      .subscribe();

    this.groupLessonWaitService.audioOuputDeviceChanged$
      .pipe(
        untilDestroyed(this),
        tap(audioOuputDeviceId => {
          // console.log('audioOuputDeviceId ', audioOuputDeviceId)
          this.openViduService.publishAudioOutputDevice(audioOuputDeviceId);
        }),
      )
      .subscribe();

    this.openViduService.mediaDevicesAvailable$.subscribe((v: boolean) => {
      if (!v) {
        this.showCameraUnavailableModal();
      }
    });

    this.groupLessonSignalrService.onReconnected
      .pipe(
        tap(() => {
          this.subscription.unsubscribe();
        }),
        switchMap(() =>
          this.lessonsService.apiV1LessonsLessonIdGet({
            lessonId: +this.queryChatLessonId,
          }),
        ),
        tap(resp => {
          console.log('переподключении сокетов')
          // при переподключении сокетов обновляем состояние мемберов. полная переинициализация группового звонка теперь по сигналам опенвиду.
          this.chatLesson = resp.data;
          const membersState = resp.data.members.map(x => ({
            memberId: x.memberId,
            previousValue: [],
            currentValue: x.publishingState,
          }));
          this.groupLessonPublishingStateService.setMembersState(membersState);
        }),
      )
      .subscribe();
    //wait?id=6707e2f0-0000-000d-0e00-0e1e0d09e64d&invite=32897f66d79b4fa3acbc39481bf4c1f9
    //wait?id=03d29b51-0000-0001-0d1e-0e1e0d09e64d&invite=2ed6cf6abe4a44b1bf7294ca95151dbe
    this.openViduService.networkDisconnect$
      .pipe(
        tap(() => {
          console.log('networkDisconnect$ ')
          this.subscription.unsubscribe();
          this.groupLessonSignalrService.closeHubConnection();
        }),
        switchMap(() =>
          this.lessonsService.apiV1LessonsLessonIdGet({
            lessonId: +this.queryChatLessonId,
          }),
        ),
        tap(resp => {
          this.chatLesson = resp.data;
          this.startSession(this.chatLesson, true);
        }),
      )
      .subscribe();

    this.groupLessonLayoutService.view$
      .pipe(
        untilDestroyed(this),
        tap(value => {
          const checkVerticalSecondView = this.checkAvailableVerticalSecondView(
            value,
            this.documentRef.querySelector('.video-container-split-area')?.getBoundingClientRect()?.width,
          );
          if (!checkVerticalSecondView) {
            this.view = value;
          }
        }),
      )
      .subscribe();

    this.groupLessonPinService.pinnedMode$
      .pipe(
        untilDestroyed(this),
        tap(value => {
          this.pinnedMode = value;
          if (!this.pinnedMode) {
            this.view = this.viewType.all;
          }
        }),
      )
      .subscribe();

    this.groupLessonPinService.pinnedMember$
      .pipe(
        untilDestroyed(this),
        tap(value => {
          this.pinnedMember = value;

          this.sortMembers$.next();
        }),
      )
      .subscribe();

    this.groupLessonParticipantsOverlayService.onModerateMuteChanged
      .pipe(
        untilDestroyed(this),
        tap(value => {
          this.onMuteChangedClick(value);
        }),
      )
      .subscribe();

    this.groupLessonParticipantsOverlayService.onModerateVideoStatusChanged
      .pipe(
        untilDestroyed(this),
        tap(value => {
          this.onVideoMembersStatusChangedClick(value);
        }),
      )
      .subscribe();

    this.groupLessonParticipantsOverlayService.onModerateShareScreenChanged
      .pipe(
        untilDestroyed(this),
        tap(value => {
          // console.log('onShareScreenMembersStatusChangedClick ', value)
          this.onShareScreenMembersStatusChangedClick(value);
        }),
      )
      .subscribe();

    this.groupLessonParticipantsOverlayService.onModerateUserMuteChanged
      .pipe(
        untilDestroyed(this),
        tap(value => {
          // console.log('onMuteMemberChanged ', value)
          this.onMuteMemberChanged(value);
        }),
      )
      .subscribe();

    this.groupLessonParticipantsOverlayService.onModerateUserVideoStatusChanged
      .pipe(
        untilDestroyed(this),
        tap(value => {
          console.log('onVideoStatusMemberChanged ', value)
          this.onVideoStatusMemberChanged(value);
        }),
      )
      .subscribe();

    this.groupLessonParticipantsOverlayService.onModerateUserShareScreenChanged
      .pipe(
        untilDestroyed(this),
        tap(value => {
          console.log('onShareScreenStatusMemberChanged ', value)
          this.onShareScreenStatusMemberChanged(value);
        }),
      )
      .subscribe();

    this.openViduService.speakingWhenMuted$
      .pipe(
        untilDestroyed(this),
        debounceTime(4000),
        filter(v => v && !this.audioEnabled),
        tap(() => {
          this.groupLessonNotificationsService.createNotification(
            this.myMember,
            'Собеседники Вас не слышат, так как Ваш микрофон выключен.',
          );
          this.groupLessonNotificationsService.soundNotification$.next();
        }),
      )
      .subscribe();

    this.groupLessonService.virtualKeyboardDisplayed$
      .pipe(
        untilDestroyed(this),
        tap(state => (this.virtualKeyboardDisplayed = state)),
      )
      .subscribe();

    this.groupLessonParticipantsOverlayService.onRequestMediaStateChange$
      .pipe(
        untilDestroyed(this),
        switchMap((v: RequestChangePublishingStateWithId) => this.onRequestMediaStateChange(v)),
      )
      .subscribe();

    this.groupLessonService.disableMediaNotify$
      .pipe(
        untilDestroyed(this),
        tap(type => this.moderatorDisableMediaNotify(type)),
      )
      .subscribe();

    this.breakpointObserver
      .observe(`(min-height: ${this.narrowScreenBreakPointHeight})`)
      .pipe(
        untilDestroyed(this),
        tap(state => (this.narrowScreen = !state.matches)),
      )
      .subscribe();


    this.sortMembers$
      .pipe(
        map(() => {
          /**
           * TODO: Хитрая сортировка, чтобы не переделывать верстку:
           * из-за того, что в верстке списка участников есть условие member === pinned ? owner : member,
           * то для корректной сортировки овнера среди участников, мы будем сортировать owner-а вместо member-а
           */
          const items = this.memberClients.map(
            m =>
            ({
              actual: m,
              expected: m === this.pinnedMember ? this.ownerClient : m,
            } as ChatLessonMemberClientWrapper),
          );
          // А после сортировки, возвращаем первоначальных участников
          return sortWrapperMembersFn(items, this.memberSortType, this.speakers).map(w => w.actual);
        }),
        untilDestroyed(this),
      )
      .subscribe(sorted => {
        this.memberClients = sorted as any;
      });

    this.sortMembers$.next();
  }

  onMinimizeProlong() {
    this.groupLessonService.isProlongModalVisible$.next(false);
  }

  onExtendedProlong(prolongTime: number) {
    this.lessonsService
      .apiV1LessonsLessonIdProlongPatch({
        lessonId: this.prolongData.lessonId,
        body: { DurationMinutes: prolongTime },
      })
      .pipe(
        untilDestroyed(this),
      )
      .subscribe(
        _ => {
          this.groupLessonService.canProlong$.next(false);
        },
        error => {
          const message = error?.error?.Error?.Description;
          if (!!message?.length) {
            this.errorMessage = this.translocoService.translate(message);
          }
        }
      );
  }

  initializeLesson(chatLesson: ChatLesson) {
    this.myMember = chatLesson.me;
    this.groupLessonService.setMemberId(this.myMember.memberId);
    this.groupLessonReactionService.setMemberId(this.myMember.memberId);
    const me = chatLesson.members?.find(m => m.memberId == this.myMember.memberId);
    this.myMember.name ??= me.name;
    this.myMember.avatarFileName ??= me.avatarFileName;
    const myRole = convertChatLessonMemberRoleToUserRoles(me.role);
    const amIOwner = myRole === UserRoles.Owner;

    this.userRole = myRole;
    this.openViduService.setFeatureSubscriberPublisherEnabled(!amIOwner);
    this.isOwner = amIOwner;
    //this.isModerator = this.user.email.includes('moderator');

    this.lessonsService
      .lessonExtensionData(+this.queryChatLessonId)
      .pipe(
        tap(({ body: { data } }) => {
          if (!this.isOwner) {
            return;
          }

          this.groupLessonService.canProlongData$.next({
            lessonId: +this.queryChatLessonId,
            secondsLeft: data.secondsLeft,
            extensionTimeList: data.extensionTimeList,
          });

          if (data.canBeExtended) {
            this.groupLessonService.isProlongModalVisible$.next(true);
            this.groupLessonService.canProlong$.next(true);
          }
        }),
      )
      .subscribe();

    if (this.isOwner) {
      this.groupLessonService.isAway$.next(false);
    }

    this.initializeChatRoom(chatLesson.chatRoomId);

    this.setLessonAndStart(chatLesson);
  }

  addAudioOutputObserver(el: ElementRef, audioOutputDeviceId: string, openViduService: OpenViduService) {
    // console.log('addAudioOutputObserver audioOutputDeviceId', audioOutputDeviceId)
    this.mutationObserver = new MutationObserver(mutations => {
      mutations.forEach(mutation => {
        if (mutation.target?.nodeName === 'VIDEO' && mutation.attributeName === 'id') {
          if (document.querySelectorAll("[id*='remote-video']").length > 0 && audioOutputDeviceId) {
            openViduService.publishAudioOutputDevice(audioOutputDeviceId);
          }
        }
      });
    });
    const config = { attributes: true, childList: true, subtree: true };
    this.mutationObserver.observe(el.nativeElement, config);
  }

  initServiceSubscriptions() {
    this.groupLessonService.chatLessonStatus$
      .pipe(
        untilDestroyed(this),
        tap((x: ChatLessonStatusEnum) => {
          this.chatLessonStatusChanged(x);
        }),
      )
      .subscribe();
  }

  chatLessonStatusChanged(status: ChatLessonStatusEnum) {
    if (status === ChatLessonStatusEnum.FinishedByTutor) {
      this.dialog.open(ModalLessonDone, {
        panelClass: 'modal-lesson-done',
        data: {},
      });
      this.leaveSessionAndNavigate();
    }
    if (status === ChatLessonStatusEnum.FinishedByTimer) {
      this.matDialog.openDialogs.map(dialog => dialog.close());
      this.dialog.open(ModalLessonDone, {
        panelClass: 'modal-lesson-done',
        data: {},
      });
      this.leaveSessionAndNavigate();
    }
    if (status === ChatLessonStatusEnum.FinishedByStudent) {
      this.dialog.open(ModalLessonFinished, {
        panelClass: 'modal-lesson-finished',
        data: {
          title: 'Занѝтие прервано учеником',
          text: 'Еѝли у ваѝ возникли трудноѝти, или вы не довольны тем, как прошло занятие, напишите нам на support@easy-teach.ru',
        },
      });
    }
  }

  getLesson() {
    //console.log('getLesson ')
    return this.lessonsService.apiV1LessonsLessonIdGet({ lessonId: +this.queryChatLessonId }).pipe(
      untilDestroyed(this),
      map(response => response.data),
    );
  }

  setLessonAndStart(chatLesson: ChatLesson) {
    if (!chatLesson) {
      this.router.navigate(['']);
      this.showBannedModal();
      return;
    }
    if (this.fromEmbeddedWindow && this.isOwner) {
      this.getLink();
    } else if (!this.fromEmbeddedWindow) {
      this.chatLesson = chatLesson;
      if (this.chatLesson.lessonStatusId === this.chatLessonStatusEnum.Started) {
        this.startSession(chatLesson, false);
      }
      if (this.isOwner && this.chatLesson.lessonStatusId === this.chatLessonStatusEnum.ReadyToStart) {
        this.startSession(chatLesson, false);
      }
      if (this.isOwner) {
        this.getLink();
      }
    }
  }

  getLink() {
    this.lessonLink = window.location.origin + '/wait?id=' + this.chatLesson.joinIdentity;

    if (
      this.chatLesson.inviteIdentity &&
      this.chatLesson.publishingPolicy?.lessonPrivacy === ChatLessonPrivacy.Public
    ) {
      this.inviteLink = this.lessonLink + '&guestInvite=' + this.chatLesson.inviteIdentity;
    }
  }

  speakerDetection(speechIds: string[]) {
    this.speakers = this.groupLessonService.getSpeakersByStreams([...this.members, this.owner], speechIds);

    this.sortMembers$.next();
  }

  onStartCall() { }

  startSession(chatLesson: ChatLesson, fromNetworkDisconnect: boolean) {
    if (this.isOwner) {
      this.groupLessonService.ownerStartLesson(chatLesson, fromNetworkDisconnect);
    } else {
      if (this.isSiteFirstLoad()) {
        const dialog = this.dialog.open(GroupLessonDialogFirstLoadComponent, {
          panelClass: '',
        });
        dialog
          .afterClosed()
          .pipe(
            untilDestroyed(this),
            tap(x => {
              this.groupLessonService.memberStartLesson(chatLesson, fromNetworkDisconnect);
            }),
          )
          .subscribe();
      } else {
        this.groupLessonService.memberStartLesson(chatLesson, fromNetworkDisconnect);
      }
    }
  }

  leaveSession() {
    console.log('leaveSession ')
    this.dialogRef = this.dialog.open(ModalGroupLessonFinish, {
      panelClass: 'modal-group-lesson-finish',
      data: {
        lesson: this.chatLesson,
      },
    });
    this.dialogRef
      .afterClosed()
      .pipe(
        untilDestroyed(this),
        switchMap(x => {
          if (x === 'finish') {
            return this.lessonsService.leaveLesson({ joinIdentity: this.chatLesson.joinIdentity }).pipe(
              catchError(e => {
                const role = this.isOwner ? 'преподавателем' : 'учеником';
                let template = `При завершении занятия ${role} получена ошибка`;
                console.error(template, e.message);
                this.leaveSessionAndNavigate();
                return EMPTY;
              }),
            );
          } else {
            return of(null);
          }
        }),
        tap(x => {
          if (x) {
            this.leaveSessionAndNavigate();
          }
        }),
      )
      .subscribe();
  }

  private moderatorDisableMediaNotify(type: ChatLessonMemberPublishingStateEnum) {
    this.dialog.open(ModalDisableMediaByModerator, {
      data: { type },
    });
  }

  leaveSessionAndNavigate() {
    this.groupLessonService.leaveSession()
      .then(x => {
        this.destroySubscriptions();
        setTimeout(() => {
          this.router.navigate(['']);
        }, 300);
        this.activeService.empty();
      });
  }

  subscribeSignalr() {
    const signalSubscription = this.signalrChatService.onSignal.subscribe(data => {
      // console.log(data);
    });
    this.subscription.add(signalSubscription);

    this.signalrService.OnJoinMe.pipe(untilDestroyed(this)).subscribe(x => {
      if (x.deviceId != this.appId) {
        this.leaveSessionAndNavigate();
      }
    });
  }

  subscribeGroupLessonSignalr() {
    const groupLessonSignalSubscription = this.groupLessonSignalrService.onChatLessonMemberUserBanned.subscribe(
      data => {
        if (data.subjectId === this.myMember.memberId) {
          this.showBannedModal();
        }
      },
    );
    this.subscription.add(groupLessonSignalSubscription);

    const groupLessonSignalSubscriptionLessonExtended = this.groupLessonSignalrService.onLessonCanBeExtended.subscribe(
      data => {
        if (this.isOwner) {
          this.groupLessonService.canProlong$.next(true);
        }
      },
    );
    this.subscription.add(groupLessonSignalSubscriptionLessonExtended);

    this.groupLessonSignalrService.onChatLessonMemberReaction
      .pipe(
        untilDestroyed(this),
        tap(x => {
          this.groupLessonReactionService.addOrUpdateReactions(x);
          if (x.type === ReactionTypeEnum.RaiseHand && x.show) {
            if (this.myMember.memberId !== x.byMemberId) {
              const clients = [...this.memberClients, this.ownerClient];

              const receivedFromUser: ChatLessonMember = clients.find(m => m.member?.memberId === x.byMemberId)?.member;

              if (receivedFromUser) {
                this.notificateUserAboutRaiseHand(receivedFromUser);
              }
            }
          }
        }),
      )
      .subscribe();

    this.groupLessonSignalrService.onChatLessonModerationMemberReactions
      .pipe(
        untilDestroyed(this),
        tap(x => {
          this.groupLessonReactionService.addOrUpdateModerateReactions(x);
        }),
      )
      .subscribe();

    this.groupLessonReactionService.updateReactionEmit$
      .pipe(
        untilDestroyed(this),
        switchMap((x: any) => {
          const request = { lessonId: this.queryChatLessonId, body: x };
          return this.lessonsService.chatLessonReactionsPatch(request);
        }),
      )
      .subscribe();
  }

  showBannedModal() {
    this.dialog.open(ModalLessonBanned, {
      panelClass: 'modal-lesson-banned',
      data: {},
    });
  }

  showCameraUnavailableModal() {
    this.dialog.open(ModalUnavailableCamera, {
      panelClass: 'modal-unavailable-camera',
    });
  }

  startShare() {
    this.groupLessonService.startShare();
  }

  stopShare() {
    this.groupLessonService.stopShare(this.userRole);
  }

  audioStatusChanged() {
    this.groupLessonService.audioStatusChange();
  }

  videoStatusChanged() {
    this.groupLessonService.videoStatusChange();
  }

  onMuteChangedClick($event) {
    const memberClientsIds = this.memberClients.map(client => client.member.user.id);

    const data: RightStatusChange = {
      type: ChatLessonMemberPublishingPermission.Audio,
      enabled: $event === AccessTypes.all ? true : false,
    };
    this.groupLessonService.batchAndSetPermissions(memberClientsIds, data);
  }

  onVideoMembersStatusChangedClick($event) {
    const memberClientsIds = this.memberClients.map(client => client.member.user.id);
    const data: RightStatusChange = {
      type: ChatLessonMemberPublishingPermission.Video,
      enabled: $event === AccessTypes.all ? true : false,
    };
    this.groupLessonService.batchAndSetPermissions(memberClientsIds, data);
  }

  onShareScreenMembersStatusChangedClick($event) {
    const memberClientsIds = this.memberClients.map(client => client.member.user.id);
    const data: RightStatusChange = {
      type: ChatLessonMemberPublishingPermission.Screen,
      enabled: $event === AccessTypes.all ? true : false,
    };
    this.groupLessonService.batchAndSetPermissions(memberClientsIds, data);
  }

  onHandOff() {
    this.groupLessonService.handOff();
  }

  onHandOn() {
    this.groupLessonService.handOn();
  }

  get isChatOpen() {
    return this.groupLessonService.chatOpen$.value;
  }

  memberSort(sortType: SortMemberTypes): void {
    this.memberSortType = sortType;

    this.sortMembers$.next();
  }

  private subscribePermission() {
    this.groupLessonPermissionService.videoPermission$
      .pipe(
        untilDestroyed(this),
        tap(x => {
          this.videoPermission = x;
        }),
      )
      .subscribe();

    this.groupLessonPermissionService.audioPermission$
      .pipe(
        untilDestroyed(this),
        tap(x => {
          this.audioPermission = x;
        }),
      )
      .subscribe();

    this.groupLessonPermissionService.screenPermission$
      .pipe(
        untilDestroyed(this),
        tap(x => {
          this.sharePermission = x;
        }),
      )
      .subscribe();
  }

  private restoreAudioState(x): void {
    // Реализовать логику для восстановления предыдущего состояния аудио
    if (this.previousAudioState) {
      // вот тут можно проверку сделать, есть ли стрим у участника...
      // this.groupLessonMemberManagerService.tryGetStream()
      // console.log('восстановить аудио', x)
    }
  }

  private subscribeWebRtcNetworkService() {
    this.openViduService.reconnecting$
      .pipe(
        untilDestroyed(this),
        tap(x => {
          this.groupLessonNotificationsService.createNotification(
            SystemUser,
            $localize`Соединение с интернетом прервано. Восстановление связи.`,
          );

          console.log('Соединение с интернетом прервано. Восстановление связи.')


          if (!this.networkDisconnect) {
            this.reconnecting = true;

            // тут можно сохранять все состояния пользователя перед потерей соединения
            this.previousAudioState = this.openViduService.audioEnabled;
          }
        }),
        switchMap(() =>
          interval(1000).pipe(
            take(5),
            map(t => t * 1000),
            takeUntil(this.openViduService.reconnected$),
            tap(x => {
              if (x === 4000) {
                console.log('networkDisconnect ', true)
                this.networkDisconnect = true;
                this.reconnecting = false;
              }
            }),
          ),
        ),
      )
      .subscribe();

    this.openViduService.reconnected$
      .pipe(
        untilDestroyed(this),
        tap(x => {
          this.groupLessonNotificationsService.createNotification(SystemUser, $localize`Соединение восстановлено.`);

          console.log('Соединение восстановлено.', x)
          this.reconnecting = false;
          this.networkDisconnect = false;
          this.restoreAudioState(x);
        }),
      )
      .subscribe();

    this.openViduService.sessionReconnected$
      .pipe(
        untilDestroyed(this),
        tap(x => {
          if (this.networkDisconnect) {
            this.groupLessonNotificationsService.createNotification(SystemUser, $localize`Сессия восстановлена.`);
            this.reconnecting = false;
            this.networkDisconnect = false;
          }
        }),
      )
      .subscribe();
  }

  onMuteMemberChanged($event: MuteMember) {
    const data: RightStatusChange = {
      type: ChatLessonMemberPublishingPermission.Audio,
      enabled: $event.audioEnabled,
    };
    this.groupLessonService.batchAndSetPermissions([$event.userData.userid], data);
  }

  onVideoStatusMemberChanged($event: VideoStatusMember) {
    const data: RightStatusChange = {
      type: ChatLessonMemberPublishingPermission.Video,
      enabled: $event.videoEnabled,
    };
    this.groupLessonService.batchAndSetPermissions([$event.userData.userid], data);
  }

  onShareScreenStatusMemberChanged($event: ShareScreenStatusMember) {
    const data: RightStatusChange = {
      type: ChatLessonMemberPublishingPermission.Screen,
      enabled: $event.shareScreenEnabled,
    };
    this.groupLessonService.batchAndSetPermissions([$event.userData.userid], data);
  }

  @HostListener('window:beforeunload')
  beforeunloadHandler() {
    this.groupLessonService.leaveSession();
  }

  destroySubscriptions() {
    this.fullViewPortGroupLessonService.stopFullTransition();
    this.groupLessonService.dispose();
    this.groupLessonPublishingStateService.dispose();
    this.subscription.unsubscribe();
    this.groupLessonMemberManagerService.dispose();
    this.groupLessonPermissionService.videoEnabled$.next(false);
    this.groupLessonPermissionService.audioEnabled$.next(false);
    this.groupLessonSignalrService.closeHubConnection();
    if (this.mutationObserver) {
      this.mutationObserver.disconnect();
    }
  }

  private updateAnyChatMessageCounter(chatRoomId) {
    this.lessonsService
      .chatUnreadMessagesCheck(chatRoomId)
      .pipe(
        tap(r => {
          if (r.body.data.anyMessages) {
            this.unreadMessageCounter++;
          }
        }),
      )
      .subscribe();
  }

  isSiteFirstLoad() {
    return this.windowRef?.history?.state === null && !this.fromEmbeddedWindow;
  }

  private notificateUserAboutMessage(chatMessage: ChatMessage) {
    const sender = chatMessage.sentByMember;
    const chatLessonMember = this.findChatLessonMemberClientById(sender.memberId);

    let attachedType;
    let messageText;
    let messageContent;

    if (chatMessage.messageText) {
      messageText = chatMessage.messageText;
      messageContent = messageText;
    }

    if (chatMessage.attachments.length > 0) {
      attachedType =
        chatMessage.attachments[0].attachmentTypeId === AttachmentTypeEnum.Picture ? '[Изображение]' : '[Документ]';
      messageContent = messageContent === undefined ? attachedType : messageContent + '\n' + attachedType;
    }

    this.groupLessonNotificationsService.createNotification(
      sender,
      messageContent,
      this.buildNotificationSettings(chatLessonMember),
    );
    this.groupLessonNotificationsService.soundNotification$.next();
  }

  private notificateUserAboutRaiseHand(member: ChatLessonMember) {
    const chatLessonMember = this.findChatLessonMemberClientById(member.memberId);

    this.groupLessonNotificationsService.createNotification(
      member,
      'Пользователь поднял руку',
      this.buildNotificationSettings(chatLessonMember),
    );
    this.groupLessonNotificationsService.soundNotification$.next();
  }

  private initializeChatRoom(chatRoomId: number) {
    this.updateAnyChatMessageCounter(chatRoomId);
    this.signalrChatService.connect(chatRoomId);
  }

  private findChatLessonMemberClientById(member: number) {
    const allClients = [...this.memberClients, this.ownerClient];
    return allClients.find(cl => cl.member.memberId === member);
  }

  private buildNotificationSettings(chatLessonMember: ChatLessonMemberClient): NotificationSettings {
    return {
      defaultIconType: this.getDefaultIconTypeByRole(chatLessonMember?.member.role),
    };
  }

  private getDefaultIconTypeByRole(chatLessonMemberRole?: ChatLessonMemberRole) {
    switch (chatLessonMemberRole) {
      case ChatLessonMemberRole.Owner:
      case ChatLessonMemberRole.Moderator:
        return DefaultIconType.Veteran;
      default:
        return DefaultIconType.Newbie;
    }
  }

  checkAvailableVerticalSecondView(view: VideoViewType, width: number): boolean {
    if (view === this.viewType.vertical && width < 760) {
      this.view = this.viewType.vertical;
      return true;
    }
    return false;
  }

  onVideoSplitAreaResized(event: ResizedEvent) {
    this.checkAvailableVerticalSecondView(this.view, event.newWidth);
  }

  onResizedByChat(event: ResizedEvent) {
    if (!this.isMobile) {
      return;
    }
    const toolBarHeight = 60;
    const bottomPadding = 4;
    if (event.newHeight) {
      this.topPositionToolbarOffsetByChat = event.newHeight - toolBarHeight - bottomPadding;
    }
    if (event.newHeight && event.oldHeight) {
      const shrink = event.newHeight < event.oldHeight;
      this.virtualKeyboardStatus(shrink);
    }
  }

  virtualKeyboardStatus(shrink: boolean) {
    if (shrink && !this.shrinkByChat) {
      this.shrinkByChat = true;
      return;
    }

    if (shrink) {
      this.groupLessonService.isProlongModalVisible$.next(false);
      this.groupLessonService.virtualKeyboardDisplayed$.next(true);
      return;
    }

    if (!shrink && this.virtualKeyboardDisplayed) {
      this.groupLessonService.virtualKeyboardDisplayed$.next(false);
      return;
    }

    this.shrinkByChat = false;
  }

  private onRequestMediaStateChange(
    request: RequestChangePublishingStateWithId,
  ): Observable<StrictHttpResponse<ChangeFieldsApiResponse>> {
    return this.lessonsService
      .apiV1LessonRequestChangePublishingState$Response(request, +this.queryChatLessonId)
      .pipe(untilDestroyed(this), first());
  }

  onClickTimeDotter() {
    if (!this.isOwner) {
      return;
    }

    this.groupLessonService.isProlongModalVisible$.next(true);
    this.groupLessonService.canProlong$.next(true);
  }
}
