import { NgModule } from '@angular/core';
import { DateAdapter } from '@angular/material/core';
import { TranslateDateAdapter } from '@ezteach/shared/cdk/translat-date-adapter';
import { TranslocoRootModule } from '@ezteach/transloco-root.module';
import { ProfileMenuModule } from '@ezteach/_components/header/profile-menu/profile-menu.module';
import { EffectsModule } from '@ngrx/effects';
import { StoreModule } from '@ngrx/store';
import { SharedModule } from '../shared/shared.module';
import { ProfileEffects, profileFeatureKey, reducer } from './+state';
import { ProfileCardAddComponent } from './components/profile-card-add/profile-card-add.component';
import { ProfileCardsComponent } from './components/profile-cards/profile-cards.component';
import { ProfileEducationComponent } from './components/profile-education/profile-education.component';
import { ProfileFinancialComponent } from './components/profile-financial/profile-financial.component';
import { ProfilePassportComponent } from './components/profile-passport/profile-passport.component';
import { ProfilePatronComponent } from './components/profile-patron/profile-patron.component';
import { ProfilePersonalComponent } from './components/profile-personal/profile-personal.component';
import { ProfileReferenceComponent } from './components/profile-reference/profile-reference.component';
import { ProfileSpecialtyComponent } from './components/profile-specialty/profile-specialty.component';
import { ProfileWorkComponent } from './components/profile-work/profile-work.component';
import { ProfileRoutingModule } from './profile-routing.module';
import { ProfileComponent } from './profile.component';
import { ProfileLocalStorageService } from './services/profile-localstorage.service';
import { ProfileTelegramBotComponent } from './components/profile-telegram-bot/profile-telegram-bot.component';
import { PhotoCropComponent } from './photo-crop/photo-crop.component';

@NgModule({
  declarations: [
    ProfileComponent,
    ProfilePersonalComponent,
    ProfileSpecialtyComponent,
    ProfilePassportComponent,
    ProfileFinancialComponent,
    ProfileEducationComponent,
    ProfileReferenceComponent,
    ProfileWorkComponent,
    ProfilePatronComponent,
    PhotoCropComponent,
    ProfileCardsComponent,
    ProfileCardAddComponent,
    ProfileTelegramBotComponent,
  ],
  imports: [
    ProfileRoutingModule,
    SharedModule,
    ProfileMenuModule,
    StoreModule.forFeature(profileFeatureKey, reducer),
    EffectsModule.forFeature([ProfileEffects]),
    TranslocoRootModule,
  ],
  providers: [
    {
      provide: DateAdapter,
      useClass: TranslateDateAdapter,
    },
    ProfileLocalStorageService,
  ],
})
export class ProfileModule {}
