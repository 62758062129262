import { BreakpointObserver } from '@angular/cdk/layout';
import { Overlay, OverlayConfig, OverlayRef, PositionStrategy } from '@angular/cdk/overlay';
import { ComponentPortal, ComponentType } from '@angular/cdk/portal';
import { ElementRef, Injectable, Injector } from '@angular/core';
import { ChatLesson } from '@ezteach/api/models';
import { CalendarApiService } from '@ezteach/calendar/services/calendar-api.service';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { Option, fromNullable } from 'fp-ts/lib/Option';
import * as moment from 'moment';
import { head, propOr } from 'ramda';
import { BehaviorSubject, EMPTY, Subject } from 'rxjs';
import { catchError, filter, switchMap, tap } from 'rxjs/operators';
import { ICreateMeetingPopupClose, ICreateSlotPopupClose, IMeetingPopupData, OpenMeetingPopupComponent } from '../components';
import { CreateMeetingPopupComponent } from '../components/create-meeting-popup/create-meeting-popup.component';
import { CALENDAR_POPUP_DATA, CALENDAR_POPUP_REF } from '../constants';
import { ICaledarPopupRef } from '../models';
import { CalendarService } from './calendar.service';
@UntilDestroy()
@Injectable({
  providedIn: 'root',
})
export class CalendarPopupService {
  private overlayRef: ICaledarPopupRef;
  eventSuccessfullyCreated$ = new BehaviorSubject<boolean>(false);
  joinIdentityCreatedLesson$ = new BehaviorSubject<string>('');
  inviteIdentityCreatedLesson$ = new BehaviorSubject<string>('');
  error$: BehaviorSubject<any> = new BehaviorSubject<any>(null);
  errorAPI$: BehaviorSubject<string> = new BehaviorSubject<string>(null);
  isGroupLesson$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  onlyGroupLessonTab$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  showSelectLessonTab$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  createNewEvent$: Subject<boolean> = new Subject<boolean>();
  isMobile: boolean;
  groupLessonId$ = new BehaviorSubject<number | null>(null);
  lessonSubject$ = new BehaviorSubject<string | null>(null);

  get openedPopup(): Option<ICaledarPopupRef> {
    return fromNullable(this.overlayRef);
  }

  constructor(
    private readonly overlay: Overlay,
    private readonly injector: Injector,
    private calendarApiService: CalendarApiService,
    private breakPointObserver: BreakpointObserver,
    private calendarService: CalendarService,
  ) {
    this.breakPointObserver
      .observe('(max-width: 1279.9px)')
      .pipe(untilDestroyed(this))
      .subscribe(({ matches }) => (this.isMobile = matches));
  }

  openCreateMeetingPopup(
    nativeElement: HTMLElement,
    data: IMeetingPopupData,
    containerElement?: HTMLElement,
  ): ICaledarPopupRef {
    this.joinIdentityCreatedLesson$.next(data?.groupLessonOptions?.joinIdentity);
    this.inviteIdentityCreatedLesson$.next(data?.groupLessonOptions?.inviteIdentity);

    return this.showPopupForElement(new ElementRef(nativeElement), CreateMeetingPopupComponent, data, containerElement);
  }

  openMeetingPopup(
    nativeElement: HTMLElement,
    data: ChatLesson,
    containerElement?: HTMLElement,
  ): ICaledarPopupRef {
    return this.showPopupForElement(new ElementRef(nativeElement), OpenMeetingPopupComponent, data, containerElement);
  }

  /* Hides current shown popup */
  hideCurrentPopup() {
    this.errorAPI$.next(null);

    if (this.overlayRef && this.overlayRef.hasAttached()) {
      this.overlayRef.close();
      this.overlayRef = null;
    }
  }

  private overlayClose(
    v: ICreateMeetingPopupClose | null = { status: false },
    ngOverlayRef: OverlayRef,
    closeSource: Subject<ICreateMeetingPopupClose>
  ) {
    this.clearState();
    this.calendarApiService?.refetchCalendarEvents$?.next();
    ngOverlayRef?.dispose();
    closeSource?.next(v);
    closeSource?.complete();
  }

  private showPopupForElement(
    elementRef: ElementRef,
    popup: ComponentType<any>,
    data: any = {},
    containerElement?: HTMLElement,
  ): ICaledarPopupRef {
    this.hideCurrentPopup();
    /* Overlay logic */
    const ngOverlayRef = this.createOverlay(elementRef);
    const _closeSource$ = new Subject<ICreateMeetingPopupClose | null>();
    this.overlayRef = Object.assign(ngOverlayRef, {
      close: (v: ICreateMeetingPopupClose | null = { status: false }) => {
        this.overlayClose(v, ngOverlayRef, _closeSource$);
        this.overlayRef = null;
      },
      afterClosed: () => _closeSource$.asObservable(),
      receiveMeetingData: (v: ICreateMeetingPopupClose | null = { status: false }): void => {
        this.afterReceivingData(v);
      },
      editLesson: (data: ICreateMeetingPopupClose) => {
        let changedHomeWorkData = {
          scheduledLessonId: data.lessonId,
          homeWorkTemplateId: data.homeWork_template_id,
        };
        const { lessonId, lessonDate, start, end, disciplineId, publishingPolicy, subject, invitedUsers, intervalId } = data;
        this.calendarApiService
          .editScheduledLesson(lessonId, lessonDate, start, end, disciplineId, publishingPolicy, subject, invitedUsers, intervalId)
          .pipe(
            switchMap(_ => this.calendarService.apiV1CalendarScheduledLessonId(changedHomeWorkData)),
            untilDestroyed(this),
            catchError(err => {
              this.errorAPI$.next(err.error.Error.Description);
              return EMPTY;
            }),
          ).subscribe(() => this.overlayRef.close());
      },
      editSlot: (data: ICreateSlotPopupClose) => {
        this.calendarApiService
          .editSlot(data)
          .pipe(
            untilDestroyed(this),
            catchError(err => {
              this.error$.next(err.error.Error.Description);
              return EMPTY;
            }),
          )
          .subscribe(() => this.overlayRef.close());
      },
    });
    const injector = Injector.create({
      providers: [
        {
          provide: CALENDAR_POPUP_REF,
          useValue: this.overlayRef,
        },
        {
          provide: CALENDAR_POPUP_DATA,
          useValue: data,
        },
      ],
      parent: this.injector,
    });
    this.overlayRef.attach(new ComponentPortal(popup, null, injector));
    /* Observe intersection of element on the container to hide / show popup */
    if (containerElement) {
      const observer = new IntersectionObserver(
        entries => {
          if (propOr(false, 'isIntersecting', head(entries))) {
            this.overlayRef.removePanelClass('ezteach-calendar-popup-panel--hidden');
          } else {
            this.overlayRef.addPanelClass('ezteach-calendar-popup-panel--hidden');
          }
        },
        { root: containerElement, threshold: 0.2 },
      );
      observer.observe(elementRef.nativeElement);
      this.overlayRef
        .afterClosed()
        .pipe(tap(() => observer.disconnect()))
        .subscribe();
    }
    this.overlayRef.backdropClick().subscribe((test) => {
      this.overlayClose(undefined, ngOverlayRef, _closeSource$);
      this.overlayRef.dispose();
    });
    return this.overlayRef;
  }

  private createOverlay(elementRef: ElementRef): OverlayRef {
    let config;
    // if (this.isMobile) {
    //   config = new OverlayConfig({
    //     positionStrategy: this.getPositionStrategy(elementRef),
    //     scrollStrategy: this.overlay.scrollStrategies.block(),
    //     height: '100%',
    //     width: '100%',
    //   });
    // }

    console.log(elementRef, this.getPositionStrategy(elementRef));
    config = new OverlayConfig({
      positionStrategy: this.getPositionStrategy(elementRef),
      scrollStrategy: this.overlay.scrollStrategies.reposition(),
      maxWidth: '220px',
      hasBackdrop: true,
      backdropClass: 'cdk-overlay-transparent-backdrop',
      panelClass: 'ezteach-calendar-popup-panel',
      disposeOnNavigation: true,
    });
    return this.overlay.create(config);
  }

  public overlayReposition() {
    console.log(this.overlayRef.updatePosition.toString());
    this.overlayRef && this.overlayRef.updatePosition();
  }

  private getPositionStrategy(elementRef: ElementRef): PositionStrategy {
    if (this.isMobile) {
      return this.overlay.position().global().centerVertically().centerHorizontally();
    }

    return this.overlay
      .position()
      .flexibleConnectedTo(elementRef)
      .withViewportMargin(0)
      .withFlexibleDimensions(false)
      .withGrowAfterOpen(true)
      .withPositions([
        {
          originX: 'end',
          originY: 'top',
          overlayX: 'start',
          overlayY: 'center',
          panelClass: 'ezteach-calendar-popup-panel--right',
        },
        {
          originX: 'start',
          originY: 'top',
          overlayX: 'end',
          overlayY: 'center',
          panelClass: 'ezteach-calendar-popup-panel--left',
        },
        {
          //originX: 'start',
          //originY: 'bottom',
          //overlayX: 'start',
          //overlayY: 'top',
          originX: 'end',
          originY: 'top',
          overlayX: 'start',
          overlayY: 'center',
          panelClass: 'ezteach-calendar-popup-panel--bottom',
        },
        {
          originX: 'start',
          originY: 'top',
          overlayX: 'start',
          overlayY: 'bottom',
          panelClass: 'ezteach-calendar-popup-panel--top',
        },
      ]);
  }

  createSlot(start, end) {
    return this.calendarApiService.createSlot(start, end);
  }

  createGroupLesson(params) {
    return this.calendarApiService.createGroupLesson(params);
  }

  async afterReceivingData(receiveRefData: ICreateMeetingPopupClose) {
    if (receiveRefData.status) {
      /* If is confirmed then create meeting */
      let start = receiveRefData.start;
      let end = receiveRefData.end;

      start = moment(
        moment(receiveRefData?.start ?? start)
          .utc()
          .format('YYYY-MM-DD HH:mm:ss'),
      ).toDate();
      end = moment(
        moment(receiveRefData?.end ?? end)
          .utc()
          .format('YYYY-MM-DD HH:mm:ss'),
      ).toDate();

      try {
        if (this.isGroupLesson$.value) {
          const params = {
            body: {
              lessonDate: receiveRefData.lessonDate,
              lessonStartTime: receiveRefData.start,
              lessonEndTime: receiveRefData.end,
              disciplineId: receiveRefData.disciplineId,
              publishingPolicy: receiveRefData.publishingPolicy,
              invitedUsers: receiveRefData.invitedUsers,
              intervalId: receiveRefData.intervalId,
              subject: receiveRefData.subject,
              homeWork_template_id: receiveRefData.homeWork_template_id,
            },
          };
          this.calendarApiService
            .createGroupLesson(params)
            .pipe(
              tap(result => {
                this.joinIdentityCreatedLesson$.next(result.data.joinIdentity);
                this.inviteIdentityCreatedLesson$.next(result.data.inviteIdentity);
                this.eventSuccessfullyCreated$.next(true);
                this.createNewEvent$.next(true);
                this.groupLessonId$.next(result.data?.id);
                this.lessonSubject$.next(result.data?.subject);
              }),
              filter(_ => !!receiveRefData.homeWork_template_id),
              switchMap(result => {
                const paramsId = {
                  scheduledLessonId: result.data.id,
                  homeWorkTemplateId: receiveRefData.homeWork_template_id,
                };
                return this.calendarService.apiV1CalendarScheduledLessonId(paramsId);
              }),
            )
            .subscribe({
              error: ({ error }) => {
                this.error$.next(error.Error.Description);
                return EMPTY;
              },
            });
        } else {
          this.createSlot(start, end).subscribe({
            next: () => {
              this.eventSuccessfullyCreated$.next(true);
            },
            error: ({ error }) => {
              this.error$.next(error.Error.Description);
              return EMPTY;
            },
          });
        }
      } catch (e) { }
    }
  }

  private clearState(): void {
    this.eventSuccessfullyCreated$.next(false);
    this.isGroupLesson$.next(false);
    this.joinIdentityCreatedLesson$.next('');
    this.error$.next(null);
    this.errorAPI$.next(null);
    this.resetOnlyGroupLessonTab();
    this.groupLessonId$.next(null);
    this.lessonSubject$.next(null);
  }

  private resetOnlyGroupLessonTab(): void {
    const onlyGroupLessonTab = this.onlyGroupLessonTab$.value;
    if (onlyGroupLessonTab) {
      this.onlyGroupLessonTab$.next(false);
    }
  }
}
