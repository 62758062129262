import {  Component, OnInit,Input,Output,EventEmitter } from '@angular/core';
import { UntilDestroy } from '@ngneat/until-destroy';

import { ConnectionPositionPair } from '@angular/cdk/overlay';

//import { environment } from '../../environments/environment';

//import tippy, { Instance, Props } from 'tippy.js';

@UntilDestroy()
@Component({
  selector: 'photo-crop',
  templateUrl: './photo-crop.component.html',
  styleUrls: ['./photo-crop.component.scss'],
})
export class PhotoCropComponent implements OnInit {
  @Input()
  imageUrl:string;//url изображения
  @Input()
  scale: any=1;//Масштабирование изображения
  @Input()
  offsetX: number = 0;//Сдвиг аватара по X;
  @Input()
  offsetY: number = 0;//Сдвиг аватара по Y;
  @Output()
  onSaveCrop: EventEmitter<any> = new EventEmitter<any>();
  avatarBackground="";
  imageWidth: number=370;
  imageHeight: number=370;
  proportion:number= 1;//Пропорция длина/высота
  
  //environment = environment;
  imageScaleSettingOpened=false;//Открытие окна
  positions = [
    new ConnectionPositionPair({ originX: 'start', originY: 'top' }, { overlayX: 'start', overlayY: 'top' }, 0, -6),
    new ConnectionPositionPair(
      { originX: 'start', originY: 'top' },
      { overlayX: 'start', overlayY: 'bottom' }),
      new ConnectionPositionPair({ originX: 'start', originY: 'center' }, { overlayX: 'start', overlayY: 'center' }, 0, -6),
    new ConnectionPositionPair({ originX: 'end', originY: 'center' }, { overlayX: 'end', overlayY: 'center' }, 0, -6),
  ];

  constructor (
    
  ) { }

  ngOnInit() {
    if (this.scale==1){
      this.avatarBackground=`center /100% url('${this.imageUrl}') no-repeat`//Формируем background из параметров  
    }
    else{
      this.avatarBackground=`left ${(-this.offsetX)/3.7/(this.scale-1)}% top ${(-this.offsetY)/3.7/(this.scale -1)}% / ${100*this.scale}% url('${this.imageUrl}') no-repeat`//Формируем background из параметров  
    }
    
  }

  openAvatarCenter(event) { //Открываем окно масштабирования аватар
    var image=new Image();
    image.src=this.imageUrl;
    var _this=this;
    image.onload=function(el){
      _this.proportion=image.width/image.height;//Пропорции изображения
      if (_this.proportion>1) {
        _this.imageWidth=370*_this.proportion*_this.scale;
        _this.imageHeight=370*_this.scale;
      }
      else{
        _this.imageWidth=370*_this.scale;
        _this.imageHeight=370/_this.proportion*_this.scale;
      }  //Размер с учетом матабирования    
      
      _this.imageScaleSettingOpened=true;      
    };    
  }

  ImageScaleChanged(event){//Изменение масшатаба аватара
    this.scale=event.value;
    this.imageWidth=370*this.scale * (this.proportion>=1? this.proportion: 1);
    this.imageHeight=370*this.scale / (this.proportion<=1? this.proportion: 1);
    this.offsetX=-185*(this.scale - 1);
    this.offsetY=-185*(this.scale - 1);
  }

  cropAreaOnmousedown(event) {
    let cropWrapper:any=document.getElementsByClassName("sl-cpor-image-wrapper")[0];
    let cropArea:any=document.getElementsByClassName("avatar-tooltop__image")[0];
    let _this=this;
    if (cropArea && cropWrapper) {
      var moveAt = function moveAt(pageX, pageY) {
        
        if (pageX > cropWrapper.getBoundingClientRect().left && pageX < cropWrapper.getBoundingClientRect().right) {
          //Курсор в пределах окна
          var moveX=(pageX)-(cropWrapper.getBoundingClientRect().left+shiftX);
          if (cropArea.getBoundingClientRect().left+moveX<=cropWrapper.getBoundingClientRect().left &&
          cropArea.getBoundingClientRect().right+moveX>=cropWrapper.getBoundingClientRect().right) {
            _this.offsetX=startOffsetX+moveX;
          }          
        }
        if (pageY > cropWrapper.getBoundingClientRect().top && pageY < cropWrapper.getBoundingClientRect().bottom) {
          //Курсор в пределах окна
          var moveY=(pageY)-(cropWrapper.getBoundingClientRect().top+shiftY);
          if (cropArea.getBoundingClientRect().top+moveY<=cropWrapper.getBoundingClientRect().top &&
          cropArea.getBoundingClientRect().bottom+moveY>=cropWrapper.getBoundingClientRect().bottom){
            _this.offsetY=startOffsetY+moveY;
          }          
        }        
      };

      var onMouseMove = function onMouseMove(event) {
        moveAt(event.pageX, event.pageY);
      };

      var shiftX = event.clientX - cropWrapper.getBoundingClientRect().left;
      var shiftY = event.clientY - cropWrapper.getBoundingClientRect().top;
      var startOffsetX = this.offsetX;
      var startOffsetY = this.offsetY;//отступы в начале движения
      moveAt(event.pageX, event.pageY);
      document.addEventListener('mousemove', onMouseMove);

      document.onmouseup = function () {
        document.removeEventListener('mousemove', onMouseMove);
        cropArea.onmouseup = null;
      };
    }
  }

  
  closeImageSettings() {
    this.imageScaleSettingOpened=false;    
  }

  saveImageScaleSettings() {//Сохранение настроек изображения
    if (this.scale==1){
      this.avatarBackground=`center /100% url('${this.imageUrl}') no-repeat`//Формируем background из параметров  
    }
    else{
      this.avatarBackground=`left ${(-this.offsetX)/3.7/(this.scale-1)}% top ${(-this.offsetY)/3.7/(this.scale -1)}% / ${100*this.scale}% url('${this.imageUrl}') no-repeat`//Формируем background из параметров  
    }
    this.imageScaleSettingOpened=false;
    this.onSaveCrop.emit({'proportion': this.proportion, "scale": this.scale, "offsetX" : this.offsetX, "offsetY": this.offsetY})
  }  
}
