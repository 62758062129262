<div
  [formGroup]="form"
  class="profile_common"
>
  <div class="profile_content__title">{{ 'Редактирование профиля' | transloco }}</div>
  <div class="profile_content__subtitle">{{ 'Персональные данные' | transloco }}:</div>
  <div class="profile_content__cols">
    <div class="profile_content__col">
      <mat-form-field class="profile_content__input">
        <mat-label>{{ 'Имя' | transloco }}</mat-label>
        <input
          matInput
          i18n-placeholder
          [placeholder]="'Имя' | transloco"
          maxlength="20"
          formControlName="firstName"
          readonly="{{ isReadonlyFields }}"
        />
      </mat-form-field>
    </div>
    <div class="profile_content__col">
      <mat-form-field class="profile_content__input">
        <mat-label>{{ 'Фамилия' | transloco }}</mat-label>
        <input
          matInput
          i18n-placeholder
          [placeholder]="'Фамилия' | transloco"
          maxlength="20"
          formControlName="lastName"
          readonly="{{ isReadonlyFields }}"
        />
      </mat-form-field>
    </div>
  </div>
  <div class="profile_content__cols">
    <div class="profile_content__col">
      <mat-form-field class="profile_content__input">
        <mat-label>E-mail</mat-label>
        <input
          matInput
          i18n-placeholder
          placeholder="E-mail"
          formControlName="email"
          readonly="{{ isReadonlyFields }}"
        />
      </mat-form-field>
    </div>
    <div class="profile_content__col">
      <mat-form-field class="profile_content__input mat-form-field-should-float">
        <mat-label>{{ 'Телефон' | transloco }}</mat-label>
        <div class="phone-input-container">
          <input
            value="+"
            readonly
            class="phone-prefix"
            readonly="{{ isReadonlyFields }}"
          />
          <input
            matInput
            maxlength="15"
            i18n-placeholder
            [placeholder]="'(000) 000-00-00'"
            mask="(000) 000-00-00"
            formControlName="phone"
            class="phone-number"
            readonly="{{ isReadonlyFields }}"
          />
        </div>
      </mat-form-field>
    </div>
  </div>

  <div class="profile_content__action">
    <button
      *ngIf="!(saving$ | async); else spinnerTemplate"
      (click)="setProfile()"
      class="btn btn--blue btn--submit"
      [disabled]="form.invalid"
    >
      <span class="btn__label">{{ 'Сохранить изменения' | transloco }}</span>
    </button>
    <ng-template #spinnerTemplate>
      <mat-spinner
        class="loader"
        diameter="30"
      ></mat-spinner>
    </ng-template>
  </div>
</div>
