import { ConnectedPosition, ConnectionPositionPair } from '@angular/cdk/overlay';
import { Component, ElementRef, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ChatLessonMember, RequestChangePublishingStateWithId } from '@ezteach/api/models/chat-lesson-member';
import {
  ChatLessonMemberPublishingStateEnum,
  ChatLessonMemberRole
} from '@ezteach/api/models/chat-lesson-member-permisson';
import { ReactionTypeEnum } from '@ezteach/api/models/lesson/reaction-enum';
import { ChatLessonMemberClient } from '@ezteach/group-lesson/models/chat-lesson-member-client';
import { StreamUserData } from '@ezteach/group-lesson/models/stream-user-data';
import { GroupLessonMemberManagerService } from '@ezteach/group-lesson/services/group-lesson-member-manager.service';
import { GroupLessonParticipantsOverlayService } from '@ezteach/group-lesson/services/group-lesson-participants-overlay/group-lesson-participants-overlay.service';
import { GroupLessonPermissionService } from '@ezteach/group-lesson/services/group-lesson-permisson.service/group-lesson-permisson.service';
import { GroupLessonReactionService } from '@ezteach/group-lesson/services/group-lesson-reaction-service/group-lesson-reaction.service';
import { GroupLessonService } from '@ezteach/group-lesson/services/group-lesson.service';
import { UserAvatarBackgroundPipe } from '@ezteach/shared/pipes/user-avatar-background.pipe';
import { TranslocoService } from '@ngneat/transloco';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { combineLatest } from 'rxjs';
import { tap } from 'rxjs/operators';
import tippy from 'tippy.js';
import { AccessTypes } from '../group-lesson-moderate-dropdown/group-lesson-moderate-dropdown.component';

export interface ModerateUser extends ChatLessonMember {
  videoAccess: boolean;
  audioAccess: boolean;
  shareScreenAccess: boolean;
  moderateOptionsIsOpen?: boolean;
}

@UntilDestroy()
@Component({
  selector: 'ezteach-group-lesson-participants-merge',
  templateUrl: './group-lesson-participants-list.component.html',
  styleUrls: ['./group-lesson-participants-list.component.scss'],
  providers: [UserAvatarBackgroundPipe]
})
export class GroupLessonParticipantsListComponent implements OnInit {
  isMuted: boolean;
  positionProperties!: ConnectedPosition[];
  owner: ChatLessonMember;
  users: ChatLessonMember[] = [];
  usersTeachers: ChatLessonMember[] = [];
  usersModerators: ChatLessonMember[] = [];
  usersMembers: ChatLessonMember[] = [];
  onlineUsers: ChatLessonMember[] = [];
  membersPermission: ChatLessonMember[] = [];
  notFoundIndex = -1;
  isMobile: boolean;
  private showOnlyHandsUpUsers = false;
  isSpeech = false;
  currentTooltip: any;
  tooltipAlreadyCreated = false;
  @Output() onMuteChanged = new EventEmitter<AccessTypes>();
  @Output() onVideoStatusChanged = new EventEmitter<AccessTypes>();
  @Output() requestMediaStateChange = new EventEmitter<RequestChangePublishingStateWithId>();

  @Input() isOwner: boolean;

  accessTypes = AccessTypes;
  allMemberVideoEnabled = this.accessTypes.all;
  allMemberMuted = this.accessTypes.all;
  allMemberSharedScreen = this.accessTypes.all;
  allModerateOptionsIsOpen = false;

  filterParticipantsValue: string = "";

  allModerateOptionsPositions = [
    new ConnectionPositionPair({ originX: 'end', originY: 'top' }, { overlayX: 'start', overlayY: 'top' }, 4, 0),
  ];

  mobileAllModerateOptionsPositions = [
    new ConnectionPositionPair({ originX: 'start', originY: 'top' }, { overlayX: 'end', overlayY: 'top' }, -4, 0),
  ];

  userModerateOptionsPositions = this.allModerateOptionsPositions;

  @ViewChild('tooltipBtn') tooltipBtn: ElementRef;

  constructor(
    private groupLessonMemberManagerService: GroupLessonMemberManagerService,
    private groupLessonPermissionService: GroupLessonPermissionService,
    private groupLessonParticipantsOverlayService: GroupLessonParticipantsOverlayService,
    private userAvatarBackgroundPipe: UserAvatarBackgroundPipe,
    public groupLessonService: GroupLessonService,
    public groupLessonReactionService: GroupLessonReactionService,
    private translocoService: TranslocoService,
    private route: ActivatedRoute,
    private router: Router
  ) { }

  ngOnInit(): void {
    combineLatest([
      this.groupLessonMemberManagerService.owner$,
      this.groupLessonMemberManagerService.memberClients$,
      this.groupLessonReactionService.reactions$
    ])
      .pipe(
        untilDestroyed(this)
      )
      .subscribe(([owner, members]) => {
        this.setOnlineUsers([
          owner.member,
          ...members?.map((u: ChatLessonMemberClient) => u.member) as ChatLessonMember[]
        ])
      });

    this.groupLessonPermissionService.allLessonMembers$
      .pipe(
        untilDestroyed(this),
        tap(allLessonMembers => (this.membersPermission = allLessonMembers)),
      )
      .subscribe();

    this.isMobile = this.groupLessonParticipantsOverlayService.isMobile;
  }

  public showOnlyHandsUp() {
    this.showOnlyHandsUpUsers = true;
    if (this.users) {
      this.setOnlineUsers(this.users);
    }
  }

  public setIsOwner() {
    this.isOwner = true;
  }

  public setIsSpeech(isSpeech: boolean) {
    this.isSpeech = isSpeech;
  }

  isExist(id: number) {
    return this.users.findIndex(x => x.memberId === id) > this.notFoundIndex;
  }

  closeList() {
    this.router.navigate([], {
      relativeTo: this.route,
      queryParams: {
        participants: false,
      },
      queryParamsHandling: 'merge',
    });
  }

  filterParticipants() {
    let _value = this.filterParticipantsValue.toLowerCase();

    if (_value) {
      this.usersMembers = this.onlineUsers.filter((u: ChatLessonMember) => u.name.toLowerCase().includes(_value) && u.role === "Member");
      if (!this.owner.name.toLowerCase().includes(_value)) {
        this.owner = null;
      }
    } else {
      this.usersMembers = this.onlineUsers.filter((u: ChatLessonMember) => u.role === "Member");
      this.owner = this.onlineUsers.find((u: ChatLessonMember) => u.role === ChatLessonMemberRole.Owner);
    }
  }

  setOnlineUsers(_users: ChatLessonMember[]) {
    // console.log('setOnlineUsers ', _users)
    this.users = _users;

    if (this.showOnlyHandsUpUsers) {
      const userIds = this.groupLessonReactionService.reactions$.value
        .filter(x => x.type === ReactionTypeEnum.RaiseHand)
        .map(x => x.byMemberId);
      _users = _users.filter(x => userIds.indexOf(x.memberId) !== -1);
    }

    this.onlineUsers = this.users
      .filter(user => user.role === ChatLessonMemberRole.Owner || user?.user?.isOnline)
    this.owner = this.onlineUsers.find((u: ChatLessonMember) => u.role === ChatLessonMemberRole.Owner);

    if (this.onlineUsers?.length) {
      this.filterParticipants()
    }
  }

  resetHands() {

    const request = {
      users: this.groupLessonReactionService.reactions$.value
        .filter(x => x.type === ReactionTypeEnum.RaiseHand)
        .map(x => x.byMemberId),
      reaction: { type: ReactionTypeEnum.RaiseHand, show: false },
    };
    this.groupLessonReactionService.updateReactionEmit(request);
    if (this.showOnlyHandsUpUsers) {
      this.closeList();
    }
  }

  onVideoChangedClick() {
    this.allMemberVideoEnabled =
      this.allMemberVideoEnabled === this.accessTypes.all ? this.accessTypes.none : this.accessTypes.all;
    this.groupLessonParticipantsOverlayService.onModerateVideoStatusChanged.next(this.allMemberVideoEnabled);
  }

  onMuteChangedClick() {
    this.allMemberMuted = this.allMemberMuted === this.accessTypes.all ? this.accessTypes.none : this.accessTypes.all;
    this.groupLessonParticipantsOverlayService.onModerateMuteChanged.next(this.allMemberMuted);
  }

  onShareScreenChangedClick() {
    this.allMemberSharedScreen =
      this.allMemberSharedScreen === this.accessTypes.all ? this.accessTypes.none : this.accessTypes.all;
    this.groupLessonParticipantsOverlayService.onModerateShareScreenChanged.next(this.allMemberSharedScreen);
  }

  onVideoModerateUserChangedClick(moderateUser: ModerateUser) {
    if (this.isOwner && moderateUser?.memberId !== this.owner.memberId) {
      moderateUser.videoAccess = !moderateUser.videoAccess;
      this.groupLessonParticipantsOverlayService.onModerateUserVideoStatusChanged.next({
        userData: { userid: moderateUser.user.id } as StreamUserData,
        videoEnabled: moderateUser.videoAccess,
      });
    }
  }

  onMuteModerateUserChangedClick(moderateUser: ChatLessonMember) {
    if (this.isOwner && moderateUser?.memberId !== this.owner.memberId) {
      moderateUser.audioAccess = !moderateUser.audioAccess;
      this.groupLessonParticipantsOverlayService.onModerateUserMuteChanged.next({
        userData: { userid: moderateUser.user.id } as StreamUserData,
        audioEnabled: moderateUser.audioAccess,
      });
    }
  }

  onShareScreenModerateUserChangedClick(moderateUser: ChatLessonMember) {
    moderateUser.shareScreenAccess = !moderateUser.shareScreenAccess;
    this.groupLessonParticipantsOverlayService.onModerateUserShareScreenChanged.next({
      userData: { userid: moderateUser.user.id } as StreamUserData,
      shareScreenEnabled: moderateUser.shareScreenAccess,
    });
  }

  openAllModerateOptions() {
    this.allModerateOptionsIsOpen = !this.allModerateOptionsIsOpen;
  }

  openUserModerateOptions(moderateUser: ModerateUser) {
    moderateUser.moderateOptionsIsOpen = true;
  }

  closeAllModerateOptions() {
    this.allModerateOptionsIsOpen = false;
  }

  closeUserModerateOptions(moderateUser: ModerateUser) {
    moderateUser.moderateOptionsIsOpen = false;
  }

  createTooltip() {
    if (this.tooltipAlreadyCreated) {
      return;
    }
    this.currentTooltip = tippy(this.tooltipBtn.nativeElement, this.getTooltipOptions());
    this.tooltipAlreadyCreated = true;
  }

  removeTooltip() {
    if (!this.currentTooltip) {
      return;
    }
    this.currentTooltip.destroy();
    this.tooltipAlreadyCreated = false;
  }

  getAvatar(userdata) {
    if (userdata.avatarFileName) {
      return this.userAvatarBackgroundPipe.transform(userdata);
    } else {
      return null;
    }
  }

  getTooltipOptions() {
    return {
      content: `
      <div class="tooltip-notification__denied">
        <div>${this.translocoService.translate('Опустить руку всем')}</div>
      </div>
      `,
      allowHTML: true,
      theme: 'tooltip',
    };
  }

  requestEnableAllVideo(): void {
    const request = this.createChangeMediaRequest(ChatLessonMemberPublishingStateEnum.Video, true);
    this.requestMediaStateChange.emit(request);
  }

  requestEnableAllAudio(): void {
    const request = this.createChangeMediaRequest(ChatLessonMemberPublishingStateEnum.Audio, true);
    this.requestMediaStateChange.emit(request);
  }

  requestDisableAllVideo(): void {
    const request = this.createChangeMediaRequest(ChatLessonMemberPublishingStateEnum.Video, false);
    this.requestMediaStateChange.emit(request);
  }

  requestDisableAllAudio(): void {
    const request = this.createChangeMediaRequest(ChatLessonMemberPublishingStateEnum.Audio, false);
    this.requestMediaStateChange.emit(request);
  }

  private createChangeMediaRequest(
    type: ChatLessonMemberPublishingStateEnum,
    isOn: boolean,
    memberId: number | null = null,
  ): RequestChangePublishingStateWithId {
    return {
      memberId,
      changeState: [
        {
          name: type,
          arg: '',
          isOn,
        },
      ],
    };
  }
}
