<ezteach-notification-item
  [title]="title"
  [subtitle]="subtitle"
  [needUserAction]="isCanRejected || isCanAccepted"
  [lessonId]="item.lesson.id"
  color="transparent"
  (onClose)="onDelete.emit()"
>
  <div class="notification-lesson-item__content-wrapper">
    <div class="notification-lesson-item__about">
      <ezteach-date-label [date]="date"></ezteach-date-label>
      <ezteach-time-label
        [start]="date"
        [end]="date | addDuration: durationMinutes:'m'"
      ></ezteach-time-label>
      <ezteach-icon-label
        *ngIf="discipline"
        icon="/assets/img/svg/calendar-outline.svg"
        [label]="item.lesson.discipline?.name"
      ></ezteach-icon-label>
    </div>

    <div class="divider"></div>

    <div class="notification-lesson-item__user-info">
      <div class="notification-lesson-item__avatar-wrapper">
        <div class="notification-lesson-item__avatar">
          <div class="avatar-image"
            *ngIf="item.linkedMember | userAvatarBackground as image; else initials"
            [style.background]="image"
          ></div>
          <ng-template #initials>
            <span>{{ item.linkedMember | userInitials }}</span>
          </ng-template>
        </div>
        <div
          class="notification-lesson-item__user-info-status"
          [ngClass]="{ 'online-status': true || item.linkedMember.isOnline }"
        ></div>
      </div>
      <div class="notification-lesson-item__name-info">
        <div class="notification-lesson-item__name-info__name">{{ item.linkedMember.name }}</div>
        <div
          class="notification-lesson-item__name-info__position"
          i18n
        >
          {{ item.linkedMember.isStudent ? ('Ученик' | transloco) : ('Преподаватель' | transloco) }}
        </div>
      </div>
    </div>

    <div
      class="notification-lesson-item__message"
      *ngIf="item.text"
    >{{item.text}}</div>

    <div
      *ngIf="item.user.isTutor && isCanRejected && !isExpired && !quickLesson"
      class="notification-lesson-item__communication"
    >
      <img src="assets/img/svg/notifications/icon-info.svg" />
      <span>
        {{ 'Ученик просит связаться с ним до начала занятия' | transloco }}
      </span>
    </div>

    <div
      class="notification-lesson-item__actions"
      *ngIf="!isExpired && (isCanRejected || isCanAccepted)"
    >
      <ezteach-button
        *ngIf="isCanRejected"
        bgColor="transparent"
        textColor="black"
        class="notification-lesson-item__actions__delete"
        (click)="onReject.emit(quickLesson)"
        i18n
        >{{ isAccepted ? 'Отменить' : 'Отклонить' }}</ezteach-button
      >
      <ezteach-button
        *ngIf="isCanAccepted"
        (click)="onAccept.emit(quickLesson)"
      >
        {{ 'Принять' | transloco }}
      </ezteach-button>
    </div>
  </div>
</ezteach-notification-item>
