import { DOCUMENT } from '@angular/common';
import {
  Component,
  ElementRef,
  Inject,
  OnDestroy,
  OnInit,
  Renderer2,
  RendererStyleFlags2,
  ViewChild,
} from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { NavigationEnd, Router } from '@angular/router';
import { MediaQueryService } from '@ezteach/_services/media-query.service';
import { OrientationService } from '@ezteach/_services/orientation.service';
import { UserService } from '@ezteach/_services/user.service';
import { CalendarComponent } from '@ezteach/calendar';
import { applicationLoaded } from '@ezteach/core';
import { DashboardComponent } from '@ezteach/dashboard/dashboard.component';
import { environment } from '@ezteach/enviroments';
import { GroupLessonWaitComponent } from '@ezteach/group-lesson/components/group-lesson-wait/group-lesson-wait.component';
import { GroupLessonMergeComponent } from '@ezteach/group-lesson/group-lesson.component';
import { GroupLessonService } from '@ezteach/group-lesson/services/group-lesson.service';
import { MessagesComponent } from '@ezteach/messages/messages.component';
import { ProfileService } from '@ezteach/profile';
import { FullViewPortGroupLessonService } from '@ezteach/shared/services/full-viewport-group-lesson.service';
import { TutorialApiService } from '@ezteach/tutorial';
import { WhiteboardAppComponent } from '@ezteach/whiteboard/whiteboard-app.component';
import { WINDOW } from '@ng-web-apis/common';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { Store } from '@ngrx/store';
import { AuthConfig } from 'angular-oauth2-oidc';
import * as _ from 'lodash';
import { OrientationType } from 'ngx-device-detector';
import { WhiteBoardLayoutService } from 'projects/ng-konva/src/lib/whiteboard/services/whiteboard-layout.service';
import { combineLatest, of } from 'rxjs';
import { filter, first, takeWhile, tap } from 'rxjs/operators';
import { ActiveService } from '../../_services/active.service';
import { AvailableService } from '../../_services/available.service';
import { BrowserService } from '../../_services/browser.service';
import { LocalStorageService } from '../../_services/local-storage.service';
import { RequestsService } from '../../_services/requests.service';
import { SignalrService } from '../../_services/signalr.service';
import {
  CommonValidationStatusEnum,
  UserApiResponse,
  UserBusinessRoleEnum,
  UserPagedApiResponse,
  UserProfile,
} from '../../api/models';
import { UsersService } from '../../api/services';

export const authCodeFlowConfig: AuthConfig = {
  issuer: environment.authUrl,
  redirectUri: window.location.origin,
  clientId: 'eteach.webapp.cabinet.ui.spa',
  responseType: 'code',
  scope: 'openid profile offline_access eteach.webapp.cabinet.api.public.full eteach.scope',

  showDebugInformation: true,
};

@UntilDestroy()
@Component({
  selector: 'app-app-layout',
  templateUrl: './app-layout.component.html',
  styleUrls: ['./app-layout.component.scss'],
})
export class AppLayoutComponent implements OnInit, OnDestroy {
  username: string;
  title = 'easyteach';
  userResponse: UserApiResponse;
  isAuth = false;
  note: boolean;
  userValidationStatus = CommonValidationStatusEnum.Initial;
  disableValidationNotification = true;
  profileType;
  showWarningProfile = false;
  userProfile: UserProfile;
  roomsId: Array<number>;
  unreadMessagesCount = 0;
  isMobile = false;
  isGroupLesson = false;
  isGroupLessonWait = false;
  isMessagesComponent = false;
  isWhiteBoard = false;
  isCalendarComponent = false;
  asideIsCollapsed = false;
  isDesktop = true;
  asideHeight = '';
  navHasScroll = false;
  _mainBlock: ElementRef;
  private mobileMediaQueryService = new MediaQueryService('(max-width: 1279.9px)');
  private desktopMediaQueryService = new MediaQueryService('(min-width: 1280px)');
  mobileWithoutFooterPages: string[] = ['group-lesson', 'messages', 'calendar', 'wait'];
  withoutPaddingPages: string[] = ['group-lesson'];
  withoutWarningProfilePages: string[] = ['group-lesson'];

  isMobileWithoutFooterPage: boolean;
  isWithoutPaddingPage: boolean;
  hideHeader = false;
  isWarningProfilePage: boolean;
  applicationLoaded = false;
  appVersion = environment?.appVersion;

  @ViewChild('mainBlock')
  set mainBlock(value: ElementRef) {
    this._mainBlock = value;
    if (value && this.isOnWhiteBoardPage()) {
      this.changeStyleOnWhiteboardPage();
    }
  }

  constructor(
    public dialog: MatDialog,
    private usersService: UsersService,
    private requestsService: RequestsService,
    private availableService: AvailableService,
    private activeService: ActiveService,
    private localStorageService: LocalStorageService,
    private router: Router,
    public signalRService: SignalrService,
    private store: Store,
    private browserService: BrowserService,
    private userProfileService: ProfileService,
    private renderer: Renderer2,
    private tutorialApiService: TutorialApiService,
    @Inject(WINDOW) private windowRef: Window,
    @Inject(DOCUMENT) private readonly documentRef: Document,
    public fullViewPortGroupLessonService: FullViewPortGroupLessonService,
    private whiteBoardLayoutService: WhiteBoardLayoutService,
    private groupLessonService: GroupLessonService,
    private orientationService: OrientationService,
    private userService: UserService,
  ) {
    this.loadProfile();
    this.loadRequests();
    this.activeService.update();
  }

  ngOnInit() {
    this.desktopMediaQueryService.match$.subscribe(value => {
      this.isDesktop = value;
      if (this.isDesktop) {
        if (
          this.localStorageService.get('asideIsCollapsed') !== undefined &&
          this.localStorageService.get('asideIsCollapsed') !== null &&
          this.localStorageService.get('asideIsCollapsed') !== ''
        ) {
          this.asideIsCollapsed = this.localStorageService.get('asideIsCollapsed');
        }

        setTimeout(() => {
          //начальное положение
          this.recalculateCollapseBtnPosition();

          //при клике по разделу
          const resizeObserver = new ResizeObserver(
            _.throttle(entries => {
              this.recalculateCollapseBtnPosition();
            }),
          );
          resizeObserver.observe(this.documentRef.querySelector('main'));
          resizeObserver.observe(this.documentRef.documentElement);
        }, 800);
        this.windowRef.addEventListener(
          'scroll',
          _.throttle(e => {
            //при скроле
            if (getComputedStyle(this.documentRef.documentElement).position === 'fixed') {
              return;
            } else {
              this.recalculateCollapseBtnPosition();
            }
          }),
        );
      }
    });

    this.mobileMediaQueryService.match$.subscribe(value => {
      this.isMobile = value;
      if (this.isMobile) {
        setTimeout(() => {
          const vh = this.windowRef.innerHeight * 0.01;
          this.renderer.setStyle(document.documentElement, '--vh', `${vh}px`, RendererStyleFlags2.DashCase);
          const ver = this.iOS_Version();

          if (ver && ver[0] >= 15) {
            const safeAreasHeight = this.windowRef.outerHeight - document.body.clientHeight;
            this.renderer.setStyle(
              document.documentElement,
              '--ios-15-height',
              `${safeAreasHeight / 2}px`,
              RendererStyleFlags2.DashCase,
            );
          }

          this.windowRef.addEventListener('resize', () => {
            // eslint-disable-next-line @typescript-eslint/no-shadow
            if (!this.groupLessonService.chatOpen$.value) {
              const vh = this.windowRef.innerHeight * 0.01;
              this.renderer.setStyle(document.documentElement, '--vh', `${vh}px`, RendererStyleFlags2.DashCase);
            }
          });
        }, 500);
      }
    });

    //Создаёт макротаски, из-за чего появляется проблема тестировать систему через протрактор.
    // interval(5000).pipe(
    //   untilDestroyed(this),
    //   switchMap(() => this.getUnreadMessageCount())
    // ).subscribe()

    this.userProfileService.note
      .pipe(
        tap(v => (this.note = v)),
        takeWhile(v => v !== false),
      )
      .subscribe();

    this.router.events
      .pipe(
        untilDestroyed(this),
        filter(event => event instanceof NavigationEnd),
        tap(() => {
          this.updatePageViewProperty();
        }),
      )
      .subscribe();

    this.updatePageViewProperty();

    this.layoutSubscribe();
  }

  updatePageViewProperty() {
    this.isMobileWithoutFooterPage = !!this.mobileWithoutFooterPages.find(page => this.router.url.includes(page));
    this.isWithoutPaddingPage = !!this.withoutPaddingPages.find(page => this.router.url.includes(page));
    this.isWarningProfilePage = !!this.withoutWarningProfilePages.find(page => this.router.url.includes(page));
  }

  ngOnDestroy(): void { }

  recalculateCollapseBtnPosition() {
    if (
      this.windowRef.pageYOffset + this.windowRef.innerHeight >=
      this.documentRef.querySelector('footer')?.offsetTop
    ) {
      this.asideHeight = `calc(100vh - var(--headerHeight) - ${this.windowRef.pageYOffset + this.windowRef.innerHeight - this.documentRef.querySelector('footer').offsetTop
        }px)`;
    } else {
      this.asideHeight = '';
    }
  }

  iOS_Version() {
    if (/iP(hone|od|ad)/.test(navigator.platform)) {
      // supports iOS 2.0 and later: <http://bit.ly/TJjs1V>
      const v = navigator.appVersion.match(/OS (\d+)_(\d+)_?(\d+)?/);
      // @ts-ignore
      return [parseInt(v[1], 10), parseInt(v[2], 10), parseInt(v[3] || 0, 10)];
    }
    return null;
  }

  getUnreadMessageCount() {
    this.unreadMessagesCount = 0;
    return of(0);
  }

  isValidBrowser() {
    return this.browserService.isSupportedBrowser;
  }

  loadAvailable() {
    const userdata = this.userService.userData$.value;

    if (userdata && userdata.isTutor) {
      this.availableService.init();
    }
  }

  loadBookmarks() {
    const userdata = this.userService.userData$.value;

    if (userdata && userdata.isStudent) {
      this.usersService
        .apiV1UsersUserIdBookmarksTutorsGet({
          userId: userdata.id,
        })
        .subscribe((userPagedApiResponse: UserPagedApiResponse) => {
          this.localStorageService.set(
            'bookmarks',
            userPagedApiResponse.data.map(item => item.tutor.id),
          );
        });
    }
  }

  closeNote() {
    this.userProfileService.note.next(false);
  }

  loadRequests() {
    this.requestsService.init();
  }

  loadValidationStatus() {
    this.userProfileService.validationStatus().subscribe(r => {
      this.userValidationStatus = r.data.validationStatusId;
    });
  }

  loadAdditionalProperty() {
    this.userProfileService.loadAdditionalProperty().subscribe(resp => {
      if (resp) {
        this.disableValidationNotification = resp;
      } else {
        this.disableValidationNotification = false;
      }
    });
  }

  get isTutor(): boolean {
    return this.profileType === UserBusinessRoleEnum.Tutor;
  }

  loadProfile() {
    this.userService.updateUserData$.next();
    this.userService.userData$
      .pipe(
        filter(v => !!v),
        first(),
        tap(data => {
          this.profileType = data.profileTypeId;
          this.showWarningProfile = data.isTutor;
          this.isAuth = true;
          this.loadValidationStatus();
          this.loadAdditionalProperty();
          this.loadBookmarks();
          this.loadAvailable();
        }),
      )
      .subscribe();

    //не отрабатывает метод setJivoChatContactInfo, тк нет переменной jivo_api
    // this.scriptSevice
    //   .setJivoChatContactInfo(userData.name, userData.email, userData.phone)
    //   .subscribe();
  }

  get givenAvatar() {
    const userdata = this.userService.userData$.value;
    if (userdata.avatarFileName) {
      return (
        environment.apiUrl +
        '/api/v1/files/usercontent/' +
        userdata.id +
        '/user-avatar?redirect=true&file=' +
        userdata.avatarFileName
      );
    } else {
      return null;
    }
  }

  get givenInitials() {
    const userdata = this.userService.userData$.value;
    return userdata.firstName.charAt(0).toUpperCase() + userdata.lastName.charAt(0).toUpperCase();
  }

  get givenName() {
    const userdata = this.userService.userData$.value;
    if (userdata.name) {
      return userdata.name;
    } else {
      return userdata.email;
    }
  }

  collapse() {
    this.asideIsCollapsed = !this.asideIsCollapsed;
    this.localStorageService.set('asideIsCollapsed', this.asideIsCollapsed);
  }

  onRouterOutletActivate(event: any) {
    setTimeout(() => {
      this.fullViewPortGroupLessonService.stopFullTransition();
      this.isGroupLesson = event instanceof GroupLessonMergeComponent;
      this.isGroupLessonWait = event instanceof GroupLessonWaitComponent;
      this.isWhiteBoard = event instanceof WhiteboardAppComponent;
      this.isMessagesComponent = event instanceof MessagesComponent;
      this.isCalendarComponent = event instanceof CalendarComponent;
      this.tutorialApiService.isDashboardComponent = event instanceof DashboardComponent;
      if ((this.isGroupLesson || this.isMessagesComponent || this.isCalendarComponent) && this.isMobile) {
        this.renderer.addClass(document.body, 'mobile-height');
      } else {
        this.renderer.removeClass(document.body, 'mobile-height');
      }
      if (this.isAuth && !this.applicationLoaded) {
        this.store.dispatch(applicationLoaded());
        this.applicationLoaded = true;
      }
      this.changeStyleOnWhiteboardPage();
      if (document.getElementById("skeleton")!=null){
        document.getElementById("skeleton").remove();//Удаляем скелетон
      }
      
    }, 100);
  }

  isOnWhiteBoardPage() {
    return this.router.url.includes('whiteboard');
  }

  changeStyleOnWhiteboardPage() {
    if (this.isWhiteBoard) {
      this.renderer.addClass(this._mainBlock.nativeElement, 'whiteboard-page');
      if (this.isMobile) {
        this.renderer.addClass(this._mainBlock.nativeElement, 'whiteboard-mobile');
      }
    } else {
      this.renderer.removeClass(this._mainBlock.nativeElement, 'whiteboard-page');
      this.renderer.removeClass(this._mainBlock.nativeElement, 'whiteboard-mobile');
    }
  }

  onMainBlockResize(): void {
    this.whiteBoardLayoutService.resized$.next();
  }

  layoutSubscribe() {
    const orientation$ = this.orientationService.orientation$;
    const isMobile$ = this.mobileMediaQueryService.match$;

    combineLatest([orientation$, isMobile$])
      .pipe(untilDestroyed(this))
      .subscribe(([orientation, isMobile]) => {
        if (isMobile && orientation === OrientationType.Landscape) {
          this.hideHeader = true;
        } else {
          this.hideHeader = false;
        }
      });
  }
}
