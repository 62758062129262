import { Pipe, PipeTransform } from '@angular/core';
import { User } from '@ezteach/api/models';
import { ChatLessonMember } from '@ezteach/api/models/chat-lesson-member';
import { environment } from '@ezteach/enviroments';
import { toUser } from '../helpers/user-helper';

@Pipe({ name: 'userAvatarBackground' })
export class UserAvatarBackgroundPipe implements PipeTransform {
  transform(item: ChatLessonMember | User): string {
    const user = toUser(item);
    if (!user?.avatarFileName) {
      return '';
    }
    
    var url="http://" +
    user.avatarFileName;
    var fileUrl=new URL(url);
    var scale=fileUrl.searchParams.get("scale")?+fileUrl.searchParams.get("scale"): 1;
    var offsetX=fileUrl.searchParams.get("offsetX")?+fileUrl.searchParams.get("offsetX"): 0;
    var offsetY=fileUrl.searchParams.get("offsetY")?+fileUrl.searchParams.get("offsetY"): 0;  
    return (
      scale!=1?
      `left ${(-offsetX)/3.7/(scale-1)}% top ${(-offsetY)/3.7/(scale -1)}% / ${100*scale}% url('${
              environment.apiUrl +
              '/api/v1/files/usercontent/' +
              user.id +
              '/user-avatar?redirect=true&file=' +
              user.avatarFileName.split("?")[0]}') no-repeat`//Формируем background из параметров  
      :`center/100% url('${
              environment.apiUrl +
              '/api/v1/files/usercontent/' +
              user.id +
              '/user-avatar?redirect=true&file=' +
              user.avatarFileName.split("?")[0]}') no-repeat`//Формируем background из параметров  
    );
  }
}
