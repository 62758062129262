import { ChangeDetectorRef, Component, OnDestroy, OnInit, Renderer2 } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { SignalrService } from '@ezteach/_services/signalr.service';
import { UserService } from "@ezteach/_services/user.service";
import { IMessage } from '@ezteach/group-lesson/components/group-lesson-chat/group-lesson-chat.component';
import { ProfileService } from '@ezteach/profile';
import { UserAvatarBackgroundPipe } from '@ezteach/shared/pipes/user-avatar-background.pipe';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { OAuthService } from 'angular-oauth2-oidc';
import * as moment from 'moment';
import { first, takeWhile, tap } from 'rxjs/operators';
import { environment } from '../../environments/environment';
import { SignalrChatService } from '../_services/signalr.chat.service';
import { ChatService } from '../api/services/chat.service';
import { ModalMessagesMembers } from '../modals/messages-members/modal-messages-members.component';

@UntilDestroy()
@Component({
  selector: 'Messages',
  templateUrl: './messages.component.html',
  providers: [UserAvatarBackgroundPipe]
})
export class MessagesComponent implements OnInit, OnDestroy {
  dialogRef;
  potential = [];
  rooms;
  roomId;
  roomUser;
  message;
  messages: Record<number, IMessage[]> = {};
  userdata;
  stat;
  userId;
  environment;
  unreadMessages: Record<number, IMessage[]> = {};
  filesToUpload: File[] = [];
  isSending = false;
  note: boolean;
  isMobile = false;
  isChatSafePadding = false;

  touchMoveListenerFn: () => void;
  unreadMessageTimeout: NodeJS.Timeout;

  userAvatarData: Record<string, {
    userId: string;
    avatarFileName: string;
  }> = {};

  constructor(
    private oauthService: OAuthService,
    private readonly userAvatarBackgroundPipe: UserAvatarBackgroundPipe,
    public dialog: MatDialog,
    private _cdr: ChangeDetectorRef,
    private signalrChatService: SignalrChatService,
    private chatService: ChatService,
    private profileService: ProfileService,
    private renderer: Renderer2,
    private userService: UserService,
    private readonly signalrService: SignalrService,
  ) { }

  onChangeMessages(_messages: IMessage[], _roomId: number) {
    this.messages[_roomId] = _messages;
  }

  onChangeUnreadMessages(_messages: IMessage[], _roomId: number) {
    this.unreadMessages[_roomId] = _messages;
  }

  members() {
    this.chatService.apiV1ChatPotentialmembersGet().subscribe(response => {
      this.dialogRef = this.dialog.open(ModalMessagesMembers, {
        panelClass: 'modal-messages-members',
        data: {
          list: response.data,
        },
      });

      this.dialogRef.afterClosed().subscribe(result => {
        if (result && result.status === 'start') {
          this.chatService
            .apiV1ChatPost({
              sendToUserId: result.id,
            })
            .subscribe(async request => {
              this.setRoom(request.data, true);
            });
        }
      });
    });
  }

  ngOnInit() {
    this.userdata = this.userService.userData$.value;
    this.userId = this.userdata.id;

    this.getRooms();

    this.profileService.note
      .pipe(
        tap(v => (this.note = v)),
        takeWhile(v => v !== false),
      )
      .subscribe();

    this.signalrService.OnChangeUserAvatar
      .pipe(untilDestroyed(this))
      .subscribe(data => {
        if (!data?.userId?.toString()?.length) {
          return;
        }

        this.userAvatarData[data.userId] = data;
        this.updateRoomUser();
      });
  }

  setRoom(id, isNewChat = false) {
    if (this.roomId !== id) {
      this.roomId = null;
      this._cdr.detectChanges();

      this.roomId = id;
      if (isNewChat) {
        this.updateRooms(id);
      } else {
        this.updateRoomUser();
      }

      this.getStat();

      this.renderer.addClass(document.querySelector('.messages'), 'isRoom');
      if (this.isMobile) this.renderer.addClass(document.querySelector('main.messages-view'), 'no-padding');
    }

  }

  private updateRoomUser() {
    const roomUserObject = this.rooms
      ?.filter(item => item.id === this.roomId)
      ?.map(item => item.chatRoomUsers)[0]
      ?.filter(item => item.id !== this.userdata.id)[0];

    if (!roomUserObject?.id?.toString()?.length && !this.userAvatarData?.[roomUserObject?.id]?.avatarFileName) {
      return;
    }

    roomUserObject.avatarFileName = this.userAvatarData[roomUserObject.id].avatarFileName;
    this.roomUser = { ...roomUserObject };
  }

  updateRooms(chatRoomId: number) {
    this.chatService.apiV1ChatRoomByIdGet({ chatRoomId })
      .pipe(
        untilDestroyed(this),
        first(),
        tap(({ data }) => {
          if (this.rooms.findIndex(r => r.id == chatRoomId) == -1) this.rooms.push(data);
          this.updateRoomUser();
          this.signalrChatService.connect(chatRoomId);
        })
      )
      .subscribe()
  }

  clearRoom() {
    this.roomUser = null;
    this.renderer.removeClass(document.querySelector('.messages'), 'isRoom');
    if (this.isMobile) this.renderer.removeClass(document.querySelector('main.messages-view'), 'no-padding');
  }

  getStat() {
    this.chatService
      .apiV1ChatChatRoomIdStatsGet({
        chatRoomId: this.roomId,
      })
      .subscribe(request => {
        this.stat = request.data;
      });
  }

  fileChange($event) {
    if ($event.target.files) {
      this.filesToUpload = [...$event.target.files];
    }
  }

  link(file, id) {
    return (
      environment.apiUrl +
      '/api/v1/chat/messages/' +
      id +
      '/attachments/' +
      file.id +
      '?access_token=' +
      this.oauthService.getAccessToken()
    );
  }

  image(user) {
    if (user.avatarFileName) {
      return this.userAvatarBackgroundPipe.transform(user);
    } else {
      return null;
    }
  }

  initials(user) {
    return user.firstName.charAt(0).toUpperCase() + user.lastName.charAt(0).toUpperCase();
  }

  getMessage(messages) {
    if (messages && messages[0]) {
      if (messages[0].attachments.length > 0) {
        return '(файл)';
      } else {
        return messages[0].messageText;
      }
    } else {
      return '';
    }
  }

  getLastMessageRoom(room) {
    const lastUnreadMessage = this.getLastMessage(this.unreadMessages[room.id]);
    const lastReadMessage = this.getLastMessage(this.messages[room.id]);

    return lastUnreadMessage?.content ||
      lastUnreadMessage?.attachments?.[0]?.sourceFileName ||
      lastReadMessage?.content ||
      lastReadMessage?.attachments?.[0]?.sourceFileName ||
      'Сообщений нет';
  }

  private getLastMessage(messages: IMessage[]): IMessage | undefined {
    if (!messages || messages.length === 0) {
      return undefined;
    }
    return messages[messages.length - 1];
  }

  getMessageTime(messages) {
    if (messages && messages[0]) {
      return moment(messages[0].publicationDate).format('HH:mm');
    } else {
      return '';
    }
  }

  async getRooms() {
    const request = await this.chatService.apiV1ChatRoomsGet().toPromise();
    this.rooms = request.data;
    this.rooms.forEach(room => {
      this.signalrChatService.connect(room?.id);

      room?.chatRoomUsers?.filter(user => !!user?.id?.toString()?.length).forEach(user => {
        this.userAvatarData[user.id] = {
          userId: user.id,
          avatarFileName: user.avatarFileName
        };
      });
    });
  }

  ngOnDestroy(): void {
    if (this.touchMoveListenerFn) {
      this.touchMoveListenerFn();
    }
  }
}
