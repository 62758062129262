import { Platform } from "@angular/cdk/platform";
import { APP_ID, Component, Inject, Input, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { NavigationExtras, Router } from '@angular/router';
import { LocalStorageService } from '@ezteach/_services/local-storage.service';
import { SignalrService } from '@ezteach/_services/signalr.service';
import { ChatLessonStatusEnum, User } from '@ezteach/api/models';
import { UserRoles } from '@ezteach/group-lesson/group-lesson.component';
import { ChatLessonMemberClient } from '@ezteach/group-lesson/models/chat-lesson-member-client';
import { GroupLessonMemberManagerService } from '@ezteach/group-lesson/services/group-lesson-member-manager.service';
import { GroupLessonPermissionService } from '@ezteach/group-lesson/services/group-lesson-permisson.service/group-lesson-permisson.service';
import { GroupLessonSignalrService } from '@ezteach/group-lesson/services/group-lesson-signalr-service/group-lesson-signalr-service';
import { GroupLessonService } from '@ezteach/group-lesson/services/group-lesson.service';
import { OpenViduService } from '@ezteach/group-lesson/services/open-vidu.service';
import { ModalLessonDone } from '@ezteach/modals/lesson-done/modal-lesson-done.component';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { Publisher, StreamManager } from 'openvidu-browser';
import { filter } from 'rxjs/operators';

@UntilDestroy()
@Component({
  selector: 'ezteach-group-lesson-embedded-window-merge',
  templateUrl: './group-lesson-embedded-window.component.html',
  styleUrls: ['./group-lesson-embedded-window.component.scss'],
})
export class GroupLessonEmbeddedWindowComponent implements OnInit {

  @Input() isOnGroupLesson = false;

  owner: Publisher | StreamManager;
  ownerClient: ChatLessonMemberClient;
  members: StreamManager[] = [];
  speakers: string[] = [];
  isAway = false;
  member: ChatLessonMemberClient;
  memberClients: ChatLessonMemberClient[] = [];
  isWindowClosed = false;
  user: User;
  membersWithOwner: ChatLessonMemberClient[] = [];
  groupLessonId: number;
  isMobile: boolean = false;

  constructor(
    private readonly openViduService: OpenViduService,
    private readonly groupLessonService: GroupLessonService,
    private readonly groupLessonMemberManagerService: GroupLessonMemberManagerService,
    private readonly dialog: MatDialog,
    private readonly localStorageService: LocalStorageService,
    private readonly router: Router,
    private readonly platform: Platform,
    private readonly signalrService: SignalrService,
    private readonly groupLessonSignalrService: GroupLessonSignalrService,
    private readonly groupLessonPermissionService: GroupLessonPermissionService,
    @Inject(APP_ID) private appId: string
  ) { }

  ngOnInit(): void {
    this.isMobile = this.platform.IOS || this.platform.ANDROID;

    this.openViduService.publisher$
      .pipe(
        untilDestroyed(this),
        filter(x => !!x),
        filter(() => this.ownerClient !== null && this.ownerClient !== undefined))
      .subscribe(x => {
        this.groupLessonMemberManagerService.setStream(x);
        this.groupLessonService.updateLessonMembers();
      });

    this.openViduService.screenPublisher$
      .pipe(
        untilDestroyed(this),
        filter(x => !!x),
        filter(() => this.ownerClient !== null && this.ownerClient !== undefined))
      .subscribe(x => {
        this.groupLessonMemberManagerService.setStream(x);
        this.groupLessonService.updateLessonMembers();
      });

    this.openViduService.owner$
      .pipe(
        untilDestroyed(this),
        filter(x => !!x))
      .subscribe(x => {
        this.owner = x;
        this.groupLessonMemberManagerService.setStream(x);
        this.groupLessonService.updateLessonMembers();
      });


    this.groupLessonMemberManagerService.owner$
      .pipe(
        untilDestroyed(this))
      .subscribe(x => {
        this.ownerClient = x;
        if (this.ownerClient) {
          this.member = this.ownerClient;
          this.membersWithOwner = this.memberClients.concat(this.ownerClient);
          this.isAway = false;
        }
      });

    this.groupLessonService.isAway$
      .pipe(
        untilDestroyed(this))
      .subscribe(away => {
        this.isAway = away;
      });

    this.openViduService.speakers$
      .pipe(
        untilDestroyed(this))
      .subscribe(speakerConnections => {
        this.speakerDetection(speakerConnections)
      });

    this.openViduService.members$
      .pipe(
        untilDestroyed(this))
      .subscribe(x => {
        this.members = x;
        this.groupLessonMemberManagerService.setStreams(x);
        this.groupLessonService.updateLessonMembers();
      });

    this.groupLessonMemberManagerService.memberClients$
      .pipe(
        untilDestroyed(this))
      .subscribe((x) => {
        this.memberClients = [...x]
        if (this.ownerClient) {
          this.membersWithOwner = this.memberClients.concat(this.ownerClient);
        }
        else {
          this.membersWithOwner = this.memberClients;
        }

        if (this.groupLessonService?.role && this.groupLessonService?.role !== UserRoles.Owner) {
          if (this.memberClients.findIndex(member => member?.member?.memberId === this.groupLessonService?.memberId) === -1) {
            this.isWindowClosed = true;
          }
          else {
            this.isWindowClosed = false;
          }
        }
      });

    this.groupLessonService.chatLessonStatus$
      .pipe(
        untilDestroyed(this))
      .subscribe((status: ChatLessonStatusEnum) => {
        if (status === ChatLessonStatusEnum.FinishedByTutor ||
          status === ChatLessonStatusEnum.FinishedByTimer) {
          this.onLessonDestroyed();
        }
      });

    this.signalrService.OnJoinMe.pipe(untilDestroyed(this)).subscribe(x => {
      if (x.deviceId != this.appId)
      {
        this.isWindowClosed = true;
        this.destroySubscriptions();
      }
      else
        this.isWindowClosed = false;
    });
  }

  speakerDetection(speakerConnections: string[]) {
    this.speakers = this.groupLessonService.getSpeakersByStreams([...this.members, this.owner], speakerConnections);
    const speakerIds = this.memberClients
      .filter(m => speakerConnections.includes(m?.stream?.stream?.connection?.connectionId))
      .map(m => m?.member?.user?.id);
    const speakerMembers = this.memberClients.filter(m => speakerIds.includes(m?.member?.user?.id));
    this.member = speakerMembers.length > 0 ? speakerMembers[0] : this.ownerClient;
  }

  updateGroupLessonId() {
    if (this.localStorageService.get('groupLessonId')) {
      this.groupLessonId = this.localStorageService.get('groupLessonId');
    }
  }

  onLessonDestroyed() {
    if (!this.isOnGroupLesson && this.isWindowClosed == false) {
      this.dialog.open(ModalLessonDone, {
        panelClass: 'modal-lesson-done',
        data: {},
      });
    }
    this.destroySubscriptions();
    this.isWindowClosed = true;
  }

  openGroupLesson() {
    this.updateGroupLessonId();
    if (this.groupLessonId) {
      const navigationExtras: NavigationExtras = {
        queryParams: {
          id: this.groupLessonId,
          fromEmbeddedWindow: true,
        },
      };
      this.router.navigate(['group-lesson'], navigationExtras);
    }
  }

  destroySubscriptions() {
    this.groupLessonService.dispose();
    this.groupLessonMemberManagerService.dispose();
    this.groupLessonPermissionService.videoEnabled$.next(false);
    this.groupLessonPermissionService.audioEnabled$.next(false);
    this.groupLessonSignalrService.closeHubConnection();
  }
}
