
      <div class="image-crop" (click)="openAvatarCenter($event)" id="avatarImage">
          <ng-container>
            <div
              class="action"
              type="button"
              cdkOverlayOrigin
              #trigger="cdkOverlayOrigin"
              text="Open avatar"
              (click)="openAvatarCenter($event)"
            >
            </div>
          <ng-template
            cdkConnectedOverlay
            [cdkConnectedOverlayOrigin]="trigger"
            [cdkConnectedOverlayOpen]="imageScaleSettingOpened"
            [cdkConnectedOverlayPositions]="positions"
            [cdkConnectedOverlayHasBackdrop]="true"
            [cdkConnectedOverlayBackdropClass]="'cdk-drop-down-back'"
            (backdropClick)="closeImageSettings()"
          >
            <div
              class="action overlay"
              type="button"
              cdkOverlayOrigin
              #trigger="cdkOverlayOrigin"
              text="Open menu"
              (click)="openAvatarCenter($event)"
            >
              
            </div>
            <div class="avatar-tooltop" id="avatarToolTip">
              <div class="avatar-tooltop__croparea sl-cpor-image-wrapper">
                <img class="avatar-tooltop__image" id="avatarPopupImage" [src]="imageUrl" 
                 width="{{imageWidth}}"
                 height="{{imageHeight}}" 
                 [style.marginLeft]="offsetX+'px'"
                 [style.marginTop]="offsetY+'px'" 
                 />
                <div class="avatar-tooltop__viewPort" draggable="true" (mousedown)="cropAreaOnmousedown($event)" ondragstart="return false">
                </div>  
              </div>
              <div class="avatar-tooltop__scale">
                <mat-slider min="1" max="10" step="0.5" [(value)]="scale" (input)="ImageScaleChanged($event)" (change)="ImageScaleChanged($event)"> 
                  <input matSliderThumb >
                </mat-slider>
                <input class="btn btn--blue" type="button" value="OK" (click)="saveImageScaleSettings()"/>
              </div>
            </div>
          </ng-template>
        </ng-container>
        <div class="avatar-image"
          [style.background]="avatarBackground"
        ></div>
        
      </div>
      