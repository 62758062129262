import { ScheduledLessonStatusEnum, UserSimple } from '@ezteach/api/models';
import { ChatLessonMemberRole } from '@ezteach/api/models/chat-lesson-member-permisson';
import { LessonPaymentTypeEnum } from '@ezteach/api/models/lesson-format-enum';
import { SelectedPublishingPolicy } from '@ezteach/group-lesson/services/group-lesson.service';
import { CalendarEventTypeEnum } from './calendar-event-type-enum';

export interface CalendarEvent {
  calendarEventType?: CalendarEventTypeEnum;
  joinIdentity?: string;
  description?: null | string;
  disciplineId?: null | number;
  endDate?: string | Date;
  id?: number;
  inviteIdentity?: string;
  isPast?: boolean;
  price?: null | number;
  startDate?: string | Date;
  statusId?: ScheduledLessonStatusEnum;
  studentUser?: UserSimple;
  title?: null | string;
  tutorUser?: UserSimple;
  waitId?: string | null;
  publishingPolicy?: SelectedPublishingPolicy;
  wasBanned?: boolean;
  lessonWillBeRecorded?: boolean;

  numberOfMembersExceeded?: boolean;
  lessonFinished?: boolean;
  me?: EventAboutMe;
  subject?: string;
  lessonPaymentType?: LessonPaymentTypeEnum;
}

export interface EventAboutMe {
  role?: ChatLessonMemberRole;
}
