<div class="messages_members">
  <div class="messages_members__title">{{ 'Написать сообщение' | transloco }}</div>

  <mat-form-field
    appearance="fill"
    class="form__field"
    color=""
  >
    <svg
      *ngIf="!focused"
      matPrefix
      width="20"
      height="20"
      viewBox="0 -3 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M3.90242 14.6976C0.921421 11.7166 0.921421 6.88341 3.90242 3.90241C6.88342 0.921406 11.7166 0.921406 14.6976 3.90241C17.4223 6.62716 17.6566 10.8993 15.4003 13.8901L18.4122 16.9021L17.2335 18.0804L14.2578 15.1047C11.2587 17.6718 6.74092 17.5361 3.90242 14.6976ZM13.5191 5.08092C11.1889 2.75079 7.41106 2.75079 5.08093 5.08092C2.75081 7.41105 2.75081 11.1889 5.08093 13.5191C7.41106 15.8492 11.1889 15.8492 13.5191 13.5191C15.8492 11.1889 15.8492 7.41105 13.5191 5.08092Z"
        fill="#92929D"
      />
    </svg>
    <svg
      *ngIf="focused"
      matPrefix
      width="20"
      height="20"
      viewBox="0 -3 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M3.90238 14.6976C0.921376 11.7166 0.921376 6.88341 3.90238 3.90241C6.88338 0.921406 11.7165 0.921406 14.6975 3.90241C17.4223 6.62716 17.6565 10.8993 15.4002 13.8901L18.4121 16.9021L17.2335 18.0804L14.2577 15.1047C11.2587 17.6718 6.74087 17.5361 3.90238 14.6976ZM13.519 5.08092C11.1889 2.75079 7.41102 2.75079 5.08089 5.08092C2.75076 7.41105 2.75076 11.1889 5.08089 13.5191C7.41102 15.8492 11.1889 15.8492 13.519 13.5191C15.8492 11.1889 15.8492 7.41105 13.519 5.08092Z"
        fill="#171725"
      />
    </svg>

    <input
      (focus)="madeInFocus($event)"
      (blur)="madeOutFocus($event)"
      type="text"
      name="user"
      [placeholder]="placeholder"
      matInput
      [formControl]="chatUserControl"
      [matAutocomplete]="auto"
    />
    <mat-autocomplete #auto="matAutocomplete">
      <mat-option
        style="height: 6.5rem"
        *ngFor="let candidate of filteredUsers"
        [value]="''"
      >
        <div
          class="messages_members_item"
          (click)="addChatUser(candidate)"
        >
          <div class="messages_members_item__image">
            <div class="avatar-image"
              *ngIf="image(candidate)"
              [style.background]="image(candidate)"
            ></div>
            <span *ngIf="!image(candidate)">{{ initials(candidate) }}</span>
          </div>
          <div class="messages_members_item__name">{{ candidate.firstName }} {{ candidate.lastName }}</div>
          <div class="messages_members_item__online">
            <span *ngIf="candidate.isOnline"></span>
          </div>
        </div>
      </mat-option>
    </mat-autocomplete>
  </mat-form-field>

  <div
    class="messages_members__list"
    *ngIf="data.list.length !== 0; else listEmpty"
  >
    <div
      class="messages_members_item"
      *ngFor="let user of data.list"
      (click)="startChat(user.id)"
    >
      <div class="messages_members_item__image">
        <div class="avatar-image"
              *ngIf="image(user)"
              [style.background]="image(user)"
            ></div>
        <span *ngIf="!image(user)">{{ initials(user) }}</span>
      </div>
      <div class="messages_members_item__name">{{ user.firstName }} {{ user.lastName }}</div>
      <div class="messages_members_item__online">
        <span *ngIf="user.isOnline"></span>
      </div>
    </div>
  </div>

  <ng-template #listEmpty>
    <div class="empty-list-wrapper">
      <div
        *ngIf="isTutor && !canSendMessage; else validUserInfo"
        style="display: flex"
      >
        <img
          src="/assets/img/svg/icon-active-lesson.svg"
          class="attention-icon"
        />
        <p>{{ 'Вы сможете отправлять сообщения, как только мы проверим вашу анкету' | transloco }}</p>
      </div>

      <ng-template #validUserInfo>
        <svg
          width="14"
          height="14"
          viewBox="0 0 14 14"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M6.9997 0C3.13422 0 0 3.13422 0 6.9997C0 10.8652 3.13422 14 6.9997 14C10.8652 14 14 10.8652 14 6.9997C14 3.13422 10.8652 0 6.9997 0ZM8.45689 10.8486C8.09659 10.9908 7.80978 11.0987 7.59467 11.1733C7.38015 11.248 7.13067 11.2853 6.84681 11.2853C6.41067 11.2853 6.07111 11.1787 5.82933 10.9659C5.58756 10.7532 5.46726 10.4836 5.46726 10.1559C5.46726 10.0284 5.47615 9.89807 5.49393 9.76533C5.5123 9.63259 5.54133 9.48326 5.58104 9.31556L6.032 7.72267C6.0717 7.56978 6.10607 7.42459 6.13333 7.28948C6.16059 7.15318 6.17363 7.02815 6.17363 6.91437C6.17363 6.7117 6.13156 6.56948 6.048 6.48948C5.96326 6.40948 5.80385 6.37037 5.56622 6.37037C5.45007 6.37037 5.33037 6.38756 5.2077 6.4237C5.08622 6.46104 4.98074 6.49481 4.89422 6.528L5.01333 6.03733C5.30844 5.91704 5.59111 5.81393 5.86074 5.72859C6.13037 5.64207 6.38519 5.59941 6.62519 5.59941C7.05837 5.59941 7.39259 5.70489 7.62785 5.91348C7.86193 6.12267 7.97985 6.39467 7.97985 6.72889C7.97985 6.79822 7.97156 6.9203 7.95556 7.09452C7.93956 7.26933 7.90933 7.42874 7.86548 7.57511L7.41689 9.16326C7.38015 9.29067 7.34756 9.43644 7.31793 9.59941C7.28889 9.76237 7.27467 9.88682 7.27467 9.97037C7.27467 10.1813 7.32148 10.3253 7.4163 10.4018C7.50993 10.4782 7.67407 10.5167 7.90637 10.5167C8.016 10.5167 8.13867 10.4972 8.27733 10.4593C8.41481 10.4213 8.51437 10.3876 8.57719 10.3585L8.45689 10.8486ZM8.37748 4.40237C8.1683 4.59674 7.91644 4.69393 7.62193 4.69393C7.328 4.69393 7.07437 4.59674 6.86341 4.40237C6.65363 4.208 6.54756 3.97156 6.54756 3.69541C6.54756 3.41985 6.65422 3.18281 6.86341 2.98667C7.07437 2.78993 7.328 2.69215 7.62193 2.69215C7.91644 2.69215 8.16889 2.78993 8.37748 2.98667C8.58667 3.18281 8.69156 3.41985 8.69156 3.69541C8.69156 3.97215 8.58667 4.208 8.37748 4.40237Z"
            fill="#678AFF"
          />
        </svg>
        <p>
          {{ 'На данный момент вы пока-что не' | transloco }} <br />
          {{ 'добавили ни одного ' | transloco }}{{ isTutor }}
        </p>
      </ng-template>
    </div>
  </ng-template>
</div>
<button
  class="modal__close"
  (click)="close()"
>
  <img src="/assets/img/svg/icon-close.svg" />
</button>
