<mat-form-field
  class="select-autocomplete float-field"
  [ngClass]="!auto.isOpen ? 'select-autocomplete-closed' : ''"
  appearance="fill"
>
  <mat-label>{{ label }}</mat-label>
  <input
    class="ez-input"
    tabindex="-1"
    matInput
    #input
    #trigger="matAutocompleteTrigger"
    [formControl]="inputControl"
    [matAutocomplete]="auto"
    [matAutocompletePosition]="'above'"
    (focus)="onFocusInput()"
  />
  <mat-autocomplete
    #auto="matAutocomplete"
    ngDefaultControl
    [formControl]="values"
  >
    <ng-container>
      <mat-option
        *ngFor="let item of items"
        [value]="item"
      >
        <ng-container *ngTemplateOutlet="itemTempl; context: { ctxItem: item }"> </ng-container>
      </mat-option>
    </ng-container>

    <ng-container *ngIf="filteredItems$ | async as filteredItems">
      <ng-container *ngFor="let item of filteredItems">
        <mat-option
          *ngIf="!isSelectedById(item)"
          [value]="item"
        >
          <ng-container *ngTemplateOutlet="itemTempl; context: { ctxItem: item }"> </ng-container>
        </mat-option>
      </ng-container>
    </ng-container>
  </mat-autocomplete>

  <div
    *ngIf="!auto.isOpen && suffix"
    matSuffix
    (click)="openPanel($event)"
  >
    <img src="assets/img/svg/icon-arraw-down.svg" />
  </div>
  <div
    *ngIf="auto.isOpen"
    matSuffix
    (click)="closePanel($event)"
  >
    <img src="assets/img/svg/icon-arrow-up.svg" />
  </div>
</mat-form-field>

<ng-template
  #itemTempl
  let-item="ctxItem"
>
  <div
    class="select-autocomplete-item"
    (click)="changeSelectItem($event, item)"
  >
    <ng-container *ngIf="item.id !== 0">
      <div
        class="select-autocomplete-item__img"
        *ngIf="item.avatarFileName; else noUserAvatar"
        [style.background]="item | userAvatarBackground"
      ></div>
      <ng-template #noUserAvatar>
        <span class="select-autocomplete-item__img-emt"
          ><span>{{ item | userInitials }}</span></span
        >
      </ng-template>
    </ng-container>
    <span class="select-autocomplete-item__txt">
      <span class="select-autocomplete-item__txt__name"> {{ item.firstName }} {{ item.lastName }} </span>
      <span
        *ngIf="item.id !== 0"
        class="select-autocomplete-item__txt__mail"
      >
        {{ item.email }}
      </span>
    </span>
    <span
      class="select-autocomplete-item__status"
      *ngIf="isSelected(item)"
    >
      <mat-icon class="checked">check</mat-icon>
    </span>
  </div>
</ng-template>
